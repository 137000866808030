import { Button, Col, Form, Input, Row, Space, Typography } from 'antd'
import { EMAIL_RULES, toNumber } from '../../util/commonUtil'
import { doApprove, doPay } from '../../util/payUtil'
import { getDates, getIsShortWeek, getTimes } from '../../util/campUtil'
import { useEffect, useRef, useState } from 'react'

import DateSelectInput from '../common/DateSelectInput'
import { JUNIOR_CAMPS_START } from '../../constants'
import JuniorCampsRefundPolicy from '../about/JuniorCampsRefundPolicy'
import { PayPalButtons } from '@paypal/react-paypal-js'
import Section from '../common/Section'
import TimeSelectInput from '../common/TimeSelectInput'
import _ from 'lodash'
import axios from '../../axios/axios'
import { config } from '../../util/config'
import { notifySuccess } from '../../util/notifyUtil'

const { Paragraph, Title, Text } = Typography

const colProps = { xs: 24, sm: 12, lg: 12, xl: 9 }
const required = [{ whitespace: true, required: true, message: '' }]

const ageRule = [
  {
    whitespace: true,
    type: 'number',
    transform: toNumber,
    required: true,
    min: 4,
    max: 17,
    message: 'Age must be between 4 and 17',
  },
]
const JuniorSummerCamps = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [stats, setStats] = useState()
  const [dates, setDates] = useState()
  const [times, setTimes] = useState()
  const [isShortWeek, setIsShortWeek] = useState(false)
  const [price, setPrice] = useState(0)
  const priceRef = useRef()
  priceRef.current = price

  const [success, setSuccess] = useState(false)

  const fetchStats = async () => {
    const result = await axios.get('/camp-stats')
    setStats(result.data)
    const dates = getDates(result.data)
    setDates(dates)
  }

  useEffect(() => {
    ;(async () => {
      fetchStats()
    })()
  }, [])

  const handleDateChanged = (weekKey, form) => {
    const times = getTimes(weekKey, stats)
    setTimes(times)

    const isShortWeek = getIsShortWeek(weekKey)
    setIsShortWeek(isShortWeek)

    const timekey = form.getFieldsValue(['timeslot'])?.timeslot
    setPrice(calcPrice(timekey, isShortWeek))
  }

  const calcPrice = (timeKey, isShortWeek) => {
    let price = timeKey === 'full' ? 120 : 70
    if (isShortWeek) {
      price = price * 0.8
    }
    return price
  }

  const handleTimeChanged = (timeKey) => setPrice(calcPrice(timeKey, isShortWeek))

  const handleApproveSuccess = (result, values) => {
    notifySuccess(
      'saved',
      'Successfully registered',
      <>
        A confirmation email has been sent to {values.email}. Please check your spam folder if it doesn't arrive
        shortly.
        <br />
        <br />
        You can continue to register additional participants and/or sessions.
      </>,
      10,
    )
    form.setFieldsValue({ week: undefined, timeslot: undefined })
    setLoading(false)
    setPrice(0)
    fetchStats()
  }

  const pay = async (data, actions) => doPay(priceRef.current, '2025 Summer Camp Registration', data, actions)
  const onApprove = async (data, actions) =>
    doApprove(form, '/camp-signups', handleApproveSuccess, null, true, data, actions)
  const onError = async (data, actions) => {
    console.error('A PayPal error occurred', data)
    setError(data)
  }

  return (
    <>
      <Section title="Junior Summer Camps">
        <Paragraph>
          Starting the week of {JUNIOR_CAMPS_START}, we will offer junior camps running <b>Monday - Friday</b> (no
          lessons on July 1 or August 4).
          <br />
          Lessons will be limited to 8 children per court.
        </Paragraph>
        <Paragraph>
          Available timeslots:
          <ul>
            <li>9am-10:30am ($70/week)</li>
            <li>10:45am-12:15pm ($70/week)</li>
            <li>9am-12:15pm ($120/week) (with 15 minute break)</li>
          </ul>
        </Paragraph>
        <Title level={2}>Refund Policy</Title>
        <JuniorCampsRefundPolicy />
        <Title level={2}>Register</Title>
        {!config.REGISTRATION_ENABLED && !config.EARLY_REGISTRATION_ENABLED ? (
          <Paragraph strong>Camp registration will open on April 1.</Paragraph>
        ) : (
          <>
            <Paragraph>
              Fill out the form below and click the{' '}
              <Text italic strong>
                PayPal
              </Text>{' '}
              button to sign up and pay.
              <br />
              Repeat to sign up for multiple weeks and/or multiple participants.
            </Paragraph>
            <Form
              name="registration-details"
              layout="vertical"
              size="small"
              form={form}
              className="form"
              autoComplete={false}
            >
              <Row gutter={10}>
                <Col {...colProps}>
                  <Form.Item label="Participant first name" name="firstName" rules={required}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...colProps}>
                  <Form.Item label="Participant last name" name="lastName" rules={required}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...colProps}>
                  <Form.Item label="Age" name="age" rules={ageRule}>
                    <Input style={{ maxWidth: '5em' }} />
                  </Form.Item>
                </Col>
                <Col {...colProps}>
                  <Form.Item label="Email" name="email" rules={EMAIL_RULES}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col {...colProps}>
                  <Form.Item label="Emergency contact name" name="emergContactName" rules={required}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...colProps}>
                  <Form.Item label="Emergency contact phone number" name="emergContactPhone" rules={required}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={24}>
                  <Text className="note">Session dates and times will be disabled if those sessions are full.</Text>
                </Col>
                <Col {...colProps}>
                  <DateSelectInput
                    label="Week"
                    dates={dates}
                    name="week"
                    onChange={(v) => handleDateChanged(v, form)}
                  />
                </Col>
                <Col {...colProps}>
                  <TimeSelectInput times={times} name="timeslot" onChange={(v) => handleTimeChanged(v, form)} />
                </Col>
              </Row>
              <Row gutter={10} style={{ paddingTop: '2em' }}>
                <Col span={24}>
                  {price > 0 && (
                    <Space direction="horizontal">
                      <Text style={{ fontSize: '1.2em' }}>Price: ${price}</Text>
                    </Space>
                  )}
                </Col>
              </Row>
              <Row gutter={10}>
                <Form.Item>
                  {price > 0 && (
                    <PayPalButtons
                      style={{ layout: 'vertical', color: 'gold' }}
                      fundingSource="paypal"
                      createOrder={pay}
                      onApprove={onApprove}
                      onError={onError}
                    />
                  )}
                  {/* <Button size="large" type="primary" htmlType="submit" style={{ marginTop: '2em' }}>
                    Submit
                  </Button> */}
                </Form.Item>
              </Row>
            </Form>
          </>
        )}
      </Section>
    </>
  )
}

export default JuniorSummerCamps
