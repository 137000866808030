import { Col, Form, Input, Row, Space, Typography } from 'antd'
import { EMAIL_RULES, toNumber } from '../../util/commonUtil'
import { doApprove, doPay } from '../../util/payUtil'
import { useEffect, useState } from 'react'

import { PayPalButtons } from '@paypal/react-paypal-js'
import Section from '../common/Section'
import { notifySuccess } from '../../util/notifyUtil'

const { Paragraph, Title, Text } = Typography

const colProps = { xs: 24, sm: 12, lg: 12, xl: 9 }
const required = [{ whitespace: true, required: true, message: '' }]

const ageRule = [
  {
    whitespace: true,
    type: 'number',
    transform: toNumber,
    required: true,
    min: 8,
    max: 17,
    message: 'Age must be between 8 and 17',
  },
]

const PRICE = 110

const JuniorAdvancedLessons = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  useEffect(() => {
    form.setFieldsValue({ week: 'n/a', timeslot: 'advanced' })
  }, [form])

  const handleApproveSuccess = (result, values) => {
    notifySuccess(
      'saved',
      'Successfully registered',
      <>
        A confirmation email has been sent to {values.email}. Please check your spam folder if it doesn't arrive
        shortly.
        <br />
        <br />
        You can continue to register additional participants.
      </>,
      10,
    )
    form.setFieldsValue({
      firstName: undefined,
      lastName: undefined,
      age: undefined,
      email: undefined,
      emergContactName: undefined,
      emergContactPhone: undefined,
      week: undefined,
      timeslot: undefined,
    })
    setLoading(false)
  }

  const pay = async (data, actions) => {
    return doPay(PRICE, '2025 Junior Advanced Lesson Registration', data, actions)
  }
  const onApprove = async (data, actions) =>
    doApprove(form, '/camp-signups', handleApproveSuccess, null, true, data, actions)

  const onError = async (data, actions) => {
    console.error('A PayPal error occurred', data)
    setError(data)
  }

  return (
    <>
      <Section title="Advanced Junior Lessons">
        <Paragraph>
          If you’re a junior looking to take your game to the next level then build your skills with our summer-long
          advanced junior lessons.
        </Paragraph>
        <Paragraph>
          Lessons will be run on <b>Wednesday evenings, 5:00 - 6:30pm</b>, running <b>July 2 through August 21</b>. Cost
          is <b>${PRICE}</b> for the 8 week long program.
        </Paragraph>
        <Paragraph>
          Participants must have tennis experience (i.e. be able to serve, rally, and be able to play a match)
        </Paragraph>
        <Title level={2}>Register</Title>
        <Paragraph>
          Fill out the form below and click the <i>PayPal</i> button to sign up. Repeat to sign up multiple
          participants.
        </Paragraph>
        <Form
          name="registration-details"
          layout="vertical"
          size="small"
          form={form}
          className="form"
          autoComplete={false}
        >
          <Row gutter={10}>
            <Col {...colProps}>
              <Form.Item label="Participant first name" name="firstName" rules={required}>
                <Input />
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <Form.Item label="Participant last name" name="lastName" rules={required}>
                <Input />
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <Form.Item label="Age (8-17)" name="age" rules={ageRule}>
                <Input style={{ maxWidth: '5em' }} />
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <Form.Item label="Email" name="email" rules={EMAIL_RULES}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col {...colProps}>
              <Form.Item label="Emergency contact name" name="emergContactName" rules={required}>
                <Input />
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <Form.Item label="Emergency contact phone number" name="emergContactPhone" rules={required}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} style={{ paddingTop: '2em' }}>
            <Col span={24}>
              <Space direction="horizontal">
                <Text style={{ fontSize: '1.2em' }}>Price: ${PRICE}</Text>
              </Space>
            </Col>
          </Row>
          <Row gutter={10}>
            <Form.Item>
              <PayPalButtons
                style={{ layout: 'vertical', color: 'gold' }}
                fundingSource="paypal"
                createOrder={pay}
                onApprove={onApprove}
                onError={onError}
              />
            </Form.Item>
          </Row>
        </Form>
      </Section>
    </>
  )
}

export default JuniorAdvancedLessons
