import { Divider, Space, Switch } from 'antd'
import { defaultDateTime, formatDateTime } from '../../util/dateTimeUtil'

import Section from '../common/Section'
import { Table } from 'ant-table-extensions'
import _ from 'lodash'
import { compareStrings } from '../../util/compareUtil'
import { getLessonDescription } from '../../util/adultLessonUtil'
import useAdultLessonSignups from '../../hooks/api/useAdultLessonSignups'
import { useState } from 'react'

const getDateTimeLabel = (lesson, dateFormat = 'EE MMMM d') => {
  const timeslot = lesson.timeKey ?? lesson.timeslot
  const time = timeslot === 'early' ? '7:15pm' : '8:30pm'
  const result = `${formatDateTime(lesson.date, dateFormat)} @  ${time} (${
    lesson.level === 'adv' ? `int/adv - ${getLessonDescription(lesson.date)}` : 'beg/int'
  })`
  return result
}

const summarizeData = (data) => {
  if (!data) {
    return {}
  }
  let result = data

  let recordsByTimeslot = _.groupBy(result, (r) => getDateTimeLabel(r, 'yyyy-MM-dd'))
  let summaryByTimeslot = []
  for (const timeslotKey in recordsByTimeslot) {
    const count = _.size(recordsByTimeslot[timeslotKey])
    if (count > 0) {
      const firstRecord = _.first(recordsByTimeslot[timeslotKey])
      summaryByTimeslot.push({
        key: timeslotKey,
        date: firstRecord.date,
        timeKey: firstRecord.timeslot,
        count,
        level: firstRecord.level,
      })
    }
  }

  summaryByTimeslot = _.sortBy(summaryByTimeslot, (s) => s.key)
  return { summaryByTimeslot, recordsByTimeslot }
}

const colProps = (dataIndex) => {
  return {
    sorter: (a, b) => compareStrings(_.get(a, dataIndex), _.get(b, dataIndex)),
  }
}

const AdultLessonSignupsPage = () => {
  const [groupedMode, setGroupedMode] = useState(true)
  const { signups, loading } = useAdultLessonSignups()
  let flatData = _.sortBy(signups, (d) => d.date + d.timeslot)
  flatData = _.map(flatData, (d) => ({ ...d, skill: d.user?.skill, name: d.user?.firstName + ' ' + d.user?.lastName }))
  const { summaryByTimeslot, recordsByTimeslot } = summarizeData(signups)

  const summaryColumns = [
    {
      title: 'Date / time',
      width: '25em',
      dataIndex: 'key',
      ...colProps('timeslot'),
      render: (value, record) => {
        return getDateTimeLabel(record)
      },
    },
    { title: '# Participants', dataIndex: 'count', ...colProps('count') },
  ]

  const columns = [
    { title: '', key: 'blank', width: '5em' },
    {
      title: 'Name',
      dataIndex: 'user',
      width: '10em',
      render: (value, record) => value?.firstName + ' ' + value?.lastName,
    },
    { title: 'Skill', width: '5em', dataIndex: ['user', 'skill'], ...colProps('skill') },
    {
      title: 'Signup date/time',
      dataIndex: 'createdAt',
      width: '15em',
      ...colProps('createdAt'),
      render: (text, record) => defaultDateTime(text),
    },
  ]
  const allColumns = [
    { title: 'Row', width: '3em', fixed: 'left', dataIndex: 'id', render: (value, record, index) => <>{index + 1}</> },
    { title: 'ID', dataIndex: 'id', width: '3em', ...colProps('id') },
    {
      title: 'Date',
      dataIndex: 'date',
      width: '10em',
      render: (value, record) => value,
      sorter: {
        compare: (a, b) => compareStrings(_.get(a, 'date'), _.get(b, 'date')),
        multiple: 2,
      },
    },
    {
      title: 'Time',
      dataIndex: 'timeslot',
      width: '10em',
      render: (value, record) => value,
      sorter: {
        compare: (a, b) => compareStrings(_.get(a, 'timeslot'), _.get(b, 'timeslot')),
        multiple: 1,
      },
    },
    {
      title: 'Signup date/time',
      dataIndex: 'createdAt',
      width: '15em',
      ...colProps('createdAt'),
      render: (text, record) => defaultDateTime(text),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      ...colProps('name'),
    },
    {
      title: 'Skill',
      dataIndex: 'skill',
      ...colProps('skill'),
    },
    {
      title: 'Last minute?',
      key: 'isLastMinute',
      ...colProps('isLastMinute'),
      render: (value, record) => (record.isLastMinute ? 'YES' : 'No'),
    },
  ]

  const expandedRowRender = (summaryRecord, b, c) => (
    <Table rowKey="id" columns={columns} dataSource={recordsByTimeslot?.[summaryRecord.key]} pagination={false} />
  )

  return (
    <>
      <div style={{ overflowX: 'scroll' }}>
        <Section title="Adult lesson signups" />
        <Space direction="horizontal">
          <b>Group by timeslot?</b> <Switch checked={groupedMode} onChange={() => setGroupedMode((val) => !val)} />
        </Space>
        <Divider />
        {summaryByTimeslot && groupedMode && (
          <Table
            className="compactTable"
            // style={{ minWidth: '121em' }}
            size="small"
            columns={summaryColumns}
            dataSource={summaryByTimeslot}
            expandable={{
              showExpandColumn: true,
              expandRowByClick: true,
              indentSize: 200,
              expandedRowRender,
            }}
            loading={loading}
            pagination={false}
          />
        )}
        {flatData && !groupedMode && (
          <Table
            className="compactTable"
            // style={{ minWidth: '121em' }}
            size="small"
            columns={allColumns}
            dataSource={flatData}
            loading={loading}
            pagination={false}
            exportable
            exportableProps={{ showColumnPicker: true, btnProps: { type: 'link' } }}
            searchable
          />
        )}
      </div>
    </>
  )
}

export default AdultLessonSignupsPage
