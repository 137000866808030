import { formatDateTime, parseDateTime } from '../../util/dateTimeUtil'
import { getCourtsText, getPlayerFirstNameAndLastInitial, getPlayersText } from '../../util/bookingUtil'

import { UsergroupAddOutlined as OtherIcon } from '@ant-design/icons'
import { UserOutlined as OwnedIcon } from '@ant-design/icons'
import { Typography } from 'antd'
import _ from 'lodash'

const { Text } = Typography

export const OWNED_BOOKING_ICON = (
  <OwnedIcon twoToneColor="rgb(72 203 8)" style={{ color: 'green', paddingRight: '.5em' }} />
)
export const OTHER_BOOKING_ICON = <OtherIcon twoToneColor="gray" style={{ color: 'gray', paddingRight: '.5em' }} />

const format = (time) => {
  if (_.isNil(time)) {
    return ''
  }
  const minutes = parseDateTime(time).getMinutes()
  // return formatDateTime(time, minutes === 0 ? 'h a' : 'h:mm a')
  return formatDateTime(time, 'h:mmaaa')
}

const BookingDisplay = ({ title, booking, hide, onClick }) => {
  const handleClick = () => {
    onClick?.(new Date(booking.startTime))
    hide?.()
  }

  if (!booking) {
    return 'None'
  }

  const players = '' //getPlayersText(booking)
  return (
    <span className="bookingUpcoming">
      <div
        className="upcomingBookingLink"
        onClick={handleClick}
        title={
          booking.isOwnBooking ? 'Booked by me' : `Booked by ${getPlayerFirstNameAndLastInitial(booking.createdByUser)}`
        }
      >
        {booking.isOwnBooking ? OWNED_BOOKING_ICON : OTHER_BOOKING_ICON}
        <Text className="date">{formatDateTime(booking.startTime, 'EEE MMM d')}</Text>
        <Text className="bookingUpcoming"> at </Text>
        <Text className="time">{format(booking.startTime)}</Text>
        <Text className="court">({getCourtsText(booking)})</Text>
        {!_.isEmpty(players) && (
          <>
            <Text className="players">{players}</Text>{' '}
          </>
        )}
      </div>
    </span>
  )
}

export default BookingDisplay
