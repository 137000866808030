import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import {
  PERM_ADULT_DOUBLES_ADMIN,
  PERM_ADULT_LESSONS_ADMIN,
  PERM_JUNIOR_CAMPS,
  hasPermission,
} from '../../util/authUtil'

import AboutPage from '../about/AboutPage'
import AdultDoubles from '../programs/AdultDoubles'
import AdultDoublesRegistration from '../programs/AdultDoublesRegistration'
import AdultDoublesRegistrationComplete from '../programs/AdultDoublesRegistrationComplete'
import AdultDoublesSignupsPage from '../admin/AdultDoublesSignups'
import AdultLessonSignupsPage from '../admin/AdultLessonSignups'
import AdvancedJuniorLessonSignups from '../admin/AdvancedJuniorLessons'
import AppContext from './AppContext'
import { BookingKioskPage } from '../booking/BookingKioskPage'
import BookingPage from '../booking/BookingPage'
import CalendarPage from '../calendar/CalendarPage'
import CampSignupsPage from '../admin/CampSignups'
import CoachingPage from '../about/CoachingPage'
import CodeOfConductPage from '../about/CodeOfConductPage'
import ContactPage from '../contact/ContactPage'
import CovidPage from '../about/CovidPage'
import DirectorsPage from '../about/Directors'
import DropIns from '../programs/DropIns'
import EventsPage from '../events/EventsPage'
import FaqPage from '../about/FaqPage'
import HomePage from '../home/HomePage'
import IncorporationPage from '../about/IncorporationPage'
import Interurban from '../programs/Interurban'
import JuniorAdvancedLessons from '../programs/JuniorAdvancedLessons'
import JuniorDropIns from '../programs/JuniorDropIns'
import JuniorEveningLessons from '../programs/JuniorEveningLessons'
import JuniorSummerCamps from '../programs/JuniorSummerCamps'
import LadderPage from '../programs/LadderPage'
import Lessons from '../programs/Lessons'
import MembershipPage from '../membership/MembershipPage'
import NotFoundPage from '../error/NotFoundPage'
import PayPalTest from '../membership/PayPalTest'
import PoliciesPage from '../about/PoliciesPage'
import ProfilePage from '../profile/ProfilePage'
import RefundPolicyPage from '../about/RefundPolicyPage'
import RegisterPage from '../membership/RegisterPage'
import RegistrationListPage from '../admin/RegistrationListPage'
import ResetPassword from '../login/ResetPassword'
import StringingPage from '../about/Stringing'
import UserListPage from '../admin/UserListPage'
import { useContext } from 'react'

const PrivateRoute = ({ children }) => {
  const { currentUser } = useContext(AppContext)
  return currentUser ? children : <Navigate to="/" />
}

const AdminRoute = ({ children, permission }) => {
  const { currentUser } = useContext(AppContext)
  return hasPermission(currentUser, permission) ? children : <Navigate to="/" />
}

const getRoute = (path, Component, historicalPath) => {
  return [path, historicalPath || `/pages${path}.php`].map((path) => (
    <Route key={path} exact path={path} element={<Component />} />
  ))
}

const getAdminRoutes = () => {
  return [
    <Route
      exact
      key="admin-users"
      path="/admin/users"
      element={
        <AdminRoute permission="admin">
          <UserListPage />
        </AdminRoute>
      }
    />,
    <Route
      exact
      key="admin-reg"
      path="/admin/registrations"
      element={
        <AdminRoute permission="admin">
          <RegistrationListPage />
        </AdminRoute>
      }
    />,
    <Route
      exact
      key="admin-camp-signups"
      path="/admin/camp-signups"
      element={
        <AdminRoute permission={PERM_JUNIOR_CAMPS}>
          <CampSignupsPage />
        </AdminRoute>
      }
    />,
    <Route
      exact
      key="admin-adv-camp-signups"
      path="/admin/adv-camp-signups"
      element={
        <AdminRoute permission={PERM_JUNIOR_CAMPS}>
          <AdvancedJuniorLessonSignups />
        </AdminRoute>
      }
    />,
    <Route
      exact
      key="admin-adult-lesson-signups"
      path="/admin/adult-lesson-signups"
      element={
        <AdminRoute permission={PERM_ADULT_LESSONS_ADMIN}>
          <AdultLessonSignupsPage />
        </AdminRoute>
      }
    />,
    <Route
      exact
      key="admin-adult-doubles-signups"
      path="/admin/adult-doubles-signups"
      element={
        <AdminRoute permission={PERM_ADULT_DOUBLES_ADMIN}>
          <AdultDoublesSignupsPage />
        </AdminRoute>
      }
    />,
  ]
}

const AppRoutes = () => {
  const { menuEnabled } = useContext(AppContext)
  if (!menuEnabled) {
    return (
      <Routes>
        {getRoute('/', HomePage, '/pages/index.php')}
        <Route path="*" element={<HomePage />} />
      </Routes>
    )
  }
  return (
    <Routes>
      {/* Public routes */}
      {getRoute('/', HomePage, '/pages/index.php')}

      {getRoute('/about', AboutPage)}
      {getRoute('/faq', FaqPage)}
      {getRoute('/stringing', StringingPage)}
      {getRoute('/covid', CovidPage)}
      {getRoute('/incorporation', IncorporationPage)}
      {getRoute('/policies', PoliciesPage)}
      {getRoute('/code-of-conduct', CodeOfConductPage)}
      {getRoute('/refund-policy', RefundPolicyPage)}
      {getRoute('/directors', DirectorsPage)}
      {getRoute('/coaching', CoachingPage)}

      {getRoute('/calendar', CalendarPage)}
      {getRoute('/courtbooking', BookingPage)}
      {getRoute('/courtbookingkiosk', BookingKioskPage)}
      {getRoute('/events', EventsPage)}

      {getRoute('/juniorlessons', JuniorEveningLessons)}
      {getRoute('/junioradvancedlessons', JuniorAdvancedLessons)}
      {getRoute('/juniorcamps', JuniorSummerCamps)}
      {getRoute('/juniordropins', JuniorDropIns)}
      {getRoute('/ladder', LadderPage)}
      {getRoute('/adult-doubles', AdultDoubles)}
      {getRoute('/interurban', Interurban)}
      {getRoute('/adult-doubles-register', AdultDoublesRegistration)}
      {getRoute('/adult-doubles-register-complete', AdultDoublesRegistrationComplete)}
      {getRoute('/dropins', DropIns)}
      {getRoute('/lessons', Lessons)}

      {getRoute('/membership', MembershipPage, '/pages/registration.php')}
      {getRoute('/register', RegisterPage, '/pages/registration_online.php')}
      {getRoute('/paypaltest', PayPalTest)}

      {getRoute('/contact', ContactPage)}
      {getRoute('/profile', ProfilePage)}

      {getRoute('/reset-password', ResetPassword)}

      {/* Private routes */}
      {/* <Route
        exact
        path="/profile"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      /> */}

      {getAdminRoutes()}

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
