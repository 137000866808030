import { ADULT_LESSONS_START } from '../../constants'
import AdultLessonSignup from './AdultLessonSignup'
import { EmailLink } from '../common/EmailLink'
import { Link } from 'react-router-dom'
import Section from '../common/Section'
import { Typography } from 'antd'
const { Paragraph, Title, Text } = Typography

const Lessons = () => {
  return (
    <>
      <Section title="Weekly Adult Lessons">
        <Paragraph>
          If you want to build your skills in a longer-term, structured approach, we will be offering weekly beginner
          and intermediate/advanced lessons.
        </Paragraph>
        <Paragraph>
          Lessons will be run on <b>Wednesday</b> evenings, starting <b>{ADULT_LESSONS_START}</b> until{' '}
          <b>September 11</b>.
          <ul>
            <li>
              Beginner lessons will take place at <b>7:15 - 8:15pm</b>
            </li>
            <li>
              Intermediate / advanced lessons will take place at <b>8:30 - 9:30pm</b>
            </li>
          </ul>
        </Paragraph>
        <AdultLessonSignup />
      </Section>

      <Section title="Private Lessons">
        <Paragraph>
          Private or semi-private lessons with our head coach are also available, and can be arranged by emailing the{' '}
          <Link to="/coaching">Head Pro</Link>.
        </Paragraph>
        <Paragraph>
          Non-members are welcome to take up to 4 private lessons with our head coach, but should join the club if they
          want to continue with additional lessons.
        </Paragraph>
      </Section>
    </>
  )
}

export default Lessons
