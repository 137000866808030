import { Button, Form, Select, Typography } from 'antd'

import { InfoCircleOutlined as InfoIcon } from '@ant-design/icons'
import _ from 'lodash'
import ratingsGuidePdf from '../../assets/docs/TennisCanadaSelfRatingGuide.pdf'

const { Text } = Typography

export const NtrpInfoIcon = (props) => (
  <Button {...props} type="link" icon={<InfoIcon />} href={ratingsGuidePdf} target="_blank" />
)
const required = [{ whitespace: true, required: true, message: '' }]

const skillOptions = [
  { value: '1.0', description: 'Never played' },
  { value: '1.5', description: 'New to tennis' },
  { value: '2.0', description: 'New to tennis' },
  { value: '2.5', description: 'Beginner' },
  { value: '3.0', description: 'Competent' },
  { value: '3.5', description: 'Intermediate' },
  { value: '4.0', description: 'Advanced' },
  { value: '4.5', description: 'Expert' },
  { value: '5.0', description: 'Expert' },
  { value: '5.5', description: 'Expert' },
]

const SkillLevelInput = ({ disabled = false, form }) => {
  const skill = form?.getFieldValue('skill')
  const { value, description } = _.find(skillOptions, (r) => r.value === skill) ?? {}
  return (
    <Form.Item
      label={
        <>
          Skill level <NtrpInfoIcon />
        </>
      }
      name="skill"
      rules={disabled ? [] : required}
    >
      {disabled ? (
        `${value} - ${description}`
      ) : (
        <Select dropdownMatchSelectWidth={false} disabled={disabled}>
          {skillOptions.map(({ value, description }) => (
            <Select.Option key={value}>
              {value}
              <Text italic type="secondary" style={{ paddingLeft: '2em' }}>
                {description}
              </Text>
            </Select.Option>
          ))}
        </Select>
      )}
    </Form.Item>
  )
}

export default SkillLevelInput
