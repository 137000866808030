import { Alert, Checkbox, Col, Divider, Dropdown, Form, Input, Modal, Row, Select, Space, Typography } from 'antd'
import { defaultDate, defaultTime, formatDateTime, shortDateTime } from '../../util/dateTimeUtil'

import AppContext from '../common/AppContext'
import _ from 'lodash'
import { isDate } from 'date-fns'
import { isLastMinute } from '../../util/bookingUtil'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useForm } from 'antd/lib/form/Form'
import useResponsive from '../../hooks/useResponsive'
import useUserDirectory from '../../hooks/api/useUserDirectory'
import { useWindowSize } from '../../hooks/useWindowSize'

const { Text, Paragraph, Title } = Typography

const BookingModal = ({ court, bookingTime, bookingToEdit, visible, onOk, onCancel }) => {
  const { currentUser } = useContext(AppContext)
  const isNew = _.isNil(bookingToEdit?.id)
  const [form] = Form.useForm()
  const bp = useResponsive()
  let { users, loading } = useUserDirectory(currentUser)

  const time = bookingToEdit ? bookingToEdit.startTime : bookingTime

  const handleOk = () => {
    const fields = { ...bookingToEdit, ...form.getFieldsValue(true) }
    if (!currentUser?.mayBookMultiCourt) {
      fields.showOwner = true
    }
    onOk(fields)
  }
  const handleCancel = () => {
    onCancel()
  }

  useEffect(() => {
    if (visible) {
      form.resetFields()
      if (bookingToEdit) {
        form.setFieldsValue(bookingToEdit)
      } else {
        const player1 = _.includes(
          _.map(users, (u) => u.id),
          currentUser?.id,
        )
          ? currentUser
          : null
        form.setFieldsValue({ bookingType: 'General', showOwner: true, player1 })
      }
    }
  }, [visible, form, bookingToEdit])

  const PlayerInput = ({ num }) => {
    const form = useForm()[0]
    const dirty = form.isFieldsTouched(true)
    return (
      <>
        <Col xs={20} md={21}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues[`player${num}IsGuest`] !== currentValues[`player${num}IsGuest`]
            }
          >
            {({ getFieldValue }) =>
              getFieldValue([`player${num}IsGuest`]) ? (
                <>
                  <Form.Item shouldUpdate label={`Player ${num}`} name={`guest${num}`}>
                    <Input placeholder="Enter guest's full name" autoFocus />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item shouldUpdate label={`Player ${num}`} name={[`player${num}`, 'id']}>
                    <Select
                      placeholder="Select player"
                      notFoundContent="Not found"
                      allowClear
                      showSearch
                      loading={loading}
                      options={_.map(users, (u) => ({ label: `${u.lastName}, ${u.firstName}`, value: u.id }))}
                      optionFilterProp="label"
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                    />
                  </Form.Item>
                </>
              )
            }
          </Form.Item>
        </Col>
        <Col xs={4} md={3}>
          <Form.Item style={{ maxWidth: '10em' }} label="Guest?" name={`player${num}IsGuest`} valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Col>
      </>
    )
  }

  const courts = [
    { label: 'Court 1', value: 1 },
    { label: 'Court 2', value: 2 },
    { label: 'Court 3', value: 3 },
  ]

  const isLastMinuteBooking = isNew && isDate(time) ? isLastMinute(time) : false

  return (
    <Modal
      title={null}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={isNew ? 'Save' : 'Update booking'}
      okButtonProps={{ type: 'primary' }}
      cancelButtonProps={{ type: 'link' }}
      visible={visible}
      closable={true}
      maskClosable={true}
      keyboard={true}
      destroyOnClose={true}
      width={bp.xs ? '100%' : '600px'}
    >
      <Paragraph className="bookingModalHeader" level={3}>
        {isNew ? (
          <>
            <b>{formatDateTime(time, 'EEEE MMMM d')}</b> / <b>{formatDateTime(time, 'h:mma')}</b> / <b>Court {court}</b>
          </>
        ) : (
          <>
            Edit booking: Court {court} on <b>{formatDateTime(time, 'MMM d')}</b> at{' '}
            <b>{formatDateTime(time, 'h:mm a')}</b>
          </>
        )}
      </Paragraph>
      {isLastMinuteBooking && (
        <Alert
          style={{ marginBottom: '1em' }}
          type="success"
          message={<>This is a last-minute booking and won't affect when you can book another court.</>}
        />
      )}
      <Paragraph>
        List everyone that will be using this court.{' '}
        <Text strong>Guests are permitted, but there is a limit of 3 visits per season per guest.</Text>
      </Paragraph>
      <Form form={form} size="small" layout="horizontal" colon={false}>
        <Row gutter={10}>
          <PlayerInput num={1} />
          <PlayerInput num={2} />
          <PlayerInput num={3} />
          <PlayerInput num={4} />
          <Col span={24}>
            <Form.Item label="Purpose" name="bookingType">
              <Select>
                <Select.Option key="General">General use</Select.Option>
                <Select.Option key="Lesson">Lesson</Select.Option>
                <Select.Option key="Ladder">Ladder match</Select.Option>
                <Select.Option key="Tournament">Tournament match</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {currentUser?.mayBookMultiCourt && (
            <div style={{ backgroundColor: '#f5f5f5', padding: '1em', border: '1px solid #bbb' }}>
              <Title level={2} style={{ marginTop: 0, marginBottom: '1em' }}>
                Admin options
              </Title>
              <Col span={24}>
                <Title level={3} className="noPad">
                  Description
                </Title>
                <Paragraph>This text will be displayed in the booking timeslot. (Optional)</Paragraph>
                <Form.Item label="" name="comment">
                  <Input.TextArea />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Title level={3} className="noPad">
                  Multiple courts
                </Title>
                <Paragraph>
                  You have permission to select multiple courts for this booking.{' '}
                  <b>Please ensure there are no existing bookings for the additional courts you choose here.</b>
                </Paragraph>
                <Form.Item label="" name="courts">
                  <Checkbox.Group options={courts} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Title level={3} className="noPad">
                  Show/hide username
                </Title>
                <Form.Item label="" name="showOwner" valuePropName="checked" noStyle>
                  <Checkbox />
                </Form.Item>{' '}
                Show your username on this court booking?
              </Col>
            </div>
          )}
        </Row>
      </Form>
    </Modal>
  )
}

export default BookingModal
