import { Button, Col, Row, Space, Typography } from 'antd'
import { FacebookLink, InstagramLink, MailingListLink, TwitterLink } from '../common/SocialLinks'
import { useEffect, useState } from 'react'

import { EmailLink } from '../common/EmailLink'
import { Link } from 'react-router-dom'
import Section from '../common/Section'
import useResponsive from '../../hooks/useResponsive'

const { Paragraph, Text, Title } = Typography

const Record = ({ label, value }) => (
  <tr>
    <td className="label">{label}</td>
    <td className="value">{value || <br />}</td>
  </tr>
)

const EmailContact = ({ title, email, note }) => (
  <>
    <EmailLink email={email}>
      {title}
      <Text type="secondary" italic style={{ paddingLeft: '0.7em' }} ellipsis>
        {note}
      </Text>
    </EmailLink>
    <br />
  </>
)

const ContactPage = () => {
  const bp = useResponsive()
  const [mapUrl, setMapUrl] = useState()

  useEffect(() => {
    setTimeout(
      () =>
        setMapUrl(
          'https://www.openstreetmap.org/export/embed.html?bbox=-80.31252117907742%2C43.42882354062788%2C-80.30474277293425%2C43.4331516147422',
        ),
      150,
    )
  }, [])

  return (
    <Row gutter={{ xs: 10, lg: 30, xl: 30 }}>
      <Col xs={24} xl={12}>
        <Section title="Contact info">
          <Paragraph>
            <table className="contactTable">
              {/* <Record
                label="Phone"
                value={
                  <Space direction="horizontal">
                    <Button className="noPad" type="link" href="tel:519-654-9104">
                      519-654-9104
                    </Button>
                    (clubhouse; May - August)
                  </Space>
                }
              /> */}
              <Record label="Club location" value="23 Forbes St Cambridge, ON N3C2E1" />
              <Record label="Postal address" value="131 Melran Drive, Cambridge ON N3C4C6" />
              <Record
                label="Social media"
                value={
                  <Space direction="horizontal">
                    <FacebookLink />
                    <TwitterLink />
                    <InstagramLink />
                    <MailingListLink />
                  </Space>
                }
              />
            </table>
          </Paragraph>

          <Title level={1}>Email contacts</Title>

          <Row justify="start" gutter={30}>
            <Col style={{ minWidth: '25em' }}>
              See <Link to="/directors">directors/coordinators</Link> and <Link to="/coaching">coaching staff</Link>.
            </Col>
          </Row>
        </Section>
      </Col>

      <Col xs={24} xl={12}>
        <Section title="Location">
          <iframe
            title="map"
            class="center"
            width="95%"
            height="450"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            // src="https://www.openstreetmap.org/export/embed.html?bbox=-80.31252117907742%2C43.42882354062788%2C-80.30474277293425%2C43.4331516147422"
            src={mapUrl}
            style={{ border: '1px solid black' }}
          ></iframe>
          <br />
        </Section>
      </Col>
    </Row>
  )
}

export default ContactPage
