import { Alert, Button, Col, Collapse, Divider, Row, Space, Typography } from 'antd'
import {
  LeftOutlined as BackIcon,
  FullscreenOutlined,
  RightOutlined as NextIcon,
  SyncOutlined as RefreshIcon,
} from '@ant-design/icons'
import { add, formatISO, isBefore, isSameDay, isToday, isTomorrow, sub } from 'date-fns'
import { defaultDate, durationInWords, formatDateTime, jsonDate } from '../../util/dateTimeUtil'
import { getLatestAllowedBooking, isLastMinute } from '../../util/bookingUtil'
import { hideAllNotifications, notifyError, notifyInfo, notifySuccess } from '../../util/notifyUtil'
import { useContext, useEffect, useState } from 'react'

import AppContext from '../common/AppContext'
import BookingDeleteConfirmationModal from './BookingDeleteConfirmationModal'
import BookingInstructionsModal from './BookingInstructionsModal'
import BookingMessageModal from './BookingMessageModal'
import BookingModal from './BookingModal'
import BookingsTable from './BookingsTable'
import { EmailLink } from '../common/EmailLink'
import { InvalidTimezone } from '../error/InvalidTimezone'
import { Link } from 'react-router-dom'
import NextBookingMessage from './NextBookingMessage'
import UpcomingBookings from './UpcomingBookings'
import UpcomingBookingsPopover from './UpcomingBookingsPopover'
import _ from 'lodash'
import axios from '../../axios/axios'
import { config } from '../../util/config'
import { transformServerError } from '../../util/errorUtil'
import { useLocation } from 'react-router-dom'
import { useMount } from 'ahooks'
import useQueryParams from '../../hooks/useQueryParams'
import useResponsive from '../../hooks/useResponsive'

const { Text, Paragraph, Title } = Typography

const BookingPage = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [refreshedAt, setRefreshedAt] = useState(new Date())
  const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false)
  const [selectedBookingToDelete, setSelectedBookingToDelete] = useState()
  const [messageModalVisible, setMessageModalVisible] = useState(false)
  const [modalMessage, setModalMessage] = useState()
  const [lastBooking, setLastBooking] = useState()
  const [nextAllowed, setNextAllowed] = useState()
  const [nextBookings, setNextBookings] = useState()
  const [kioskMode, setKioskMode] = useState(false)
  const [loading, setLoading] = useState()
  const [bookings, setBookings] = useState()
  const [date, setDate] = useState(new Date())
  const [selectedTimeslot, setSelectedTimeslot] = useState()
  const [selectedBooking, setSelectedBooking] = useState()
  const [selectedCourt, setSelectedCourt] = useState()
  const queryParams = useQueryParams()
  const bp = useResponsive()
  const { currentUser, validTimezone } = useContext(AppContext)
  const location = useLocation()

  const getDayControl = (date) => {
    return (
      <Button key={date} type="link" onClick={() => setDate(date)} style={{ paddingLeft: '0px' }}>
        {/* {isToday(date) ? 'Today' : isTomorrow(date) ? 'Tomorrow' : formatDateTime(date, bp.lg ? 'EEEE' : 'EEE')} */}
        {isToday(date) ? 'Today' : formatDateTime(date, bp.lg ? 'EEEE' : 'EEE')}
      </Button>
    )
  }

  const getControls = () => {
    const dayControls = []
    for (let i = 0; i <= 7; i++) {
      const day = add(new Date(), { days: i })
      dayControls.push(getDayControl(day))
    }
    return (
      <>
        <Row gutter={20} justify="space-between">
          <Col flex={1} style={{ textAlign: 'center' }}>
            {dayControls}
          </Col>
          <Col flex={1} style={{ textAlign: 'center' }}>
            <Button type="link" onClick={previousDay}>
              <BackIcon /> Previous
            </Button>
            <Button type="link" onClick={nextDay} disabled={!canMoveToNextDay}>
              Next <NextIcon />
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  const getLatestBooking = async () => {
    if (!currentUser) {
      return
    }
    try {
      setLoading(true)
      const result = await axios.get('/my/lastBooking')
      const { last, nextAllowed } = result?.data
      setLastBooking(last)
      setNextAllowed(nextAllowed)
    } catch (e) {
      notifyError('error', e)
    } finally {
      setLoading(false)
    }
  }
  const getNextBooking = async () => {
    if (!currentUser) {
      return
    }
    try {
      const result = await axios.get('/my/nextBookings')
      setNextBookings(result?.data)
    } catch (e) {
      notifyError('error', e)
    }
  }

  const getBookings = async (date) => {
    try {
      const result = await axios.get(`/all/bookings/${formatDateTime(date, 'yyyyMMdd')}`)
      setBookings(result?.data)
    } catch (e) {
      notifyError('error', e)
    }
  }

  const showMessage = (title, message) => {
    setModalMessage({ title, message })
    if (message) {
      setMessageModalVisible(true)
      hideAllNotifications()
    }
  }
  const closeMessage = () => {
    setModalMessage(null)
    setMessageModalVisible(false)
  }

  const previousDay = () => setDate((date) => sub(date, { days: 1 }))
  const nextDay = () => setDate((date) => add(date, { days: 1 }))
  const latestAllowedBooking = getLatestAllowedBooking(currentUser)
  const canMoveToNextDay = isBefore(add(date, { days: 1 }), latestAllowedBooking)

  const refresh = () => {
    getLatestBooking()
    getNextBooking()
    getBookings(date)
  }

  useEffect(() => {
    // let isKioskMode = queryParams.get('k')
    let isKioskMode = _.includes(location?.pathname, 'kiosk')
    if (isKioskMode) {
      setKioskMode(true)
      const interval = setInterval(() => {
        getBookings(date)
        setRefreshedAt(new Date())
      }, 30000)
      return () => clearInterval(interval)
    }
  }, [])

  useMount(() => {
    getLatestBooking()
    getNextBooking()
  })

  useEffect(() => {
    getLatestBooking()
    getNextBooking()
  }, [currentUser])

  useEffect(() => {
    getBookings(date)
  }, [date])

  const handleSaveBooking = (fields) => {
    const isNew = _.isNil(fields.id)
    // If the user can choose multiple courts for one booking, and they've selected
    // one or more courts, use those instead of the court they clicked on to start the booking.
    let courts
    if (isNew) {
      if (currentUser?.mayBookMultiCourt && fields.courts && _.size(fields.courts) > 0) {
        courts = _.map(fields.courts, (id) => ({ id }))
      } else {
        courts = [{ id: selectedCourt }]
      }
    } else {
      courts = fields.courts
    }
    const startTime = isNew ? formatISO(selectedTimeslot) : fields.startTime
    const endTime = isNew ? formatISO(selectedTimeslot) : fields.endTime

    const isLastMinuteBooking = isNew ? isLastMinute(startTime) : fields.isLastMinute || false
    let {
      player1IsGuest,
      player2IsGuest,
      player3IsGuest,
      player4IsGuest,
      guest1,
      guest2,
      guest3,
      guest4,
      player1,
      player2,
      player3,
      player4,
      ...rest
    } = fields

    // Clear out any prior player refs if they were changed to be guests.
    if (player1IsGuest || !player1?.id) player1 = null
    if (player2IsGuest || !player2?.id) player2 = null
    if (player3IsGuest || !player3?.id) player3 = null
    if (player4IsGuest || !player4?.id) player4 = null
    if (!!player1 || !guest1 || !player1IsGuest) {
      player1IsGuest = false
      guest1 = null
    }
    if (!!player2 || !guest2 || !player2IsGuest) {
      player2IsGuest = false
      guest2 = null
    }
    if (!!player3 || !guest3 || !player3IsGuest) {
      player3IsGuest = false
      guest3 = null
    }
    if (!!player4 || !guest4 || !player4IsGuest) {
      player4IsGuest = false
      guest4 = null
    }
    let booking = {
      ...rest,
      player1,
      player2,
      player3,
      player4,
      guest1,
      guest2,
      guest3,
      guest4,
      player1IsGuest,
      player2IsGuest,
      player3IsGuest,
      player4IsGuest,
      courts,
      startTime,
      endTime,
      isLastMinute: isLastMinuteBooking,
    }

    const url = isNew ? '/my/booking' : `/my/booking/${fields.id}`
    const save = isNew ? axios.post : axios.put

    save(url, { data: booking }).then(
      (result) => {
        if (isNew) {
          notifySuccess(
            'success',
            'Booked successfully',
            <>
              You've successfully booked a court on {formatDateTime(startTime, 'EEEE MMMM d')} at{' '}
              {formatDateTime(startTime, 'h:mma')}.
            </>,
            10,
          )
        } else {
          notifySuccess(
            'success',
            'Booking updated',

            <>Your booking changes have been saved.</>,
          )
        }
        setModalVisible(false)
        refresh()
      },
      (e) => {
        const msg = transformServerError(e)
        if (msg.code === 'EXISTING_BOOKING') {
          notifyError('error', 'Court not available', 'Sorry, someone else has already booked that court.')
          console.log('Existing bookings', e.response?.data?.error?.details?.existingBookings)
          // setModalVisible(false)
          refresh()
        } else {
          notifyError('error', e)
        }
      },
    )
  }
  const handleCancel = () => {
    setModalVisible(false)
  }

  const dateStr = formatDateTime(date, 'EEEE MMMM d')
  let displayDate
  if (isToday(date)) {
    displayDate = `${dateStr} (Today)`
  } else if (isTomorrow(date)) {
    displayDate = `${dateStr} (Tomorrow)`
  } else {
    displayDate = dateStr
  }

  const handleAdd = (time, court) => {
    if (!validTimezone) {
      return
    }
    const isLastMinuteBooking = isLastMinute(time)
    if (!currentUser) {
      showMessage('Please log in to book a court.')
      showMessage(
        'Not logged in',
        <>
          <Paragraph>Please log in to book a court.</Paragraph>
        </>,
      )
      return
    }
    if (currentUser.mayBookCourts === false) {
      showMessage(
        "You don't have permission to book courts.",
        <>
          <Paragraph>You don't have permission to book courts right now.</Paragraph>
          <Paragraph>
            Please contact <EmailLink email="webadmin" /> if you think this is an error.
          </Paragraph>
        </>,
      )
      return
    } else if (nextAllowed && !currentUser.mayIgnoreWaitPeriod && !isLastMinuteBooking) {
      showMessage(
        "You can't book this court yet.",
        <>
          <Paragraph>
            <b>You must wait at least 22 hours between court bookings.</b> You'll be able to book another court in{' '}
            {durationInWords(nextAllowed, false)} (at {formatDateTime(nextAllowed, 'h:mm a')})
          </Paragraph>
          <Paragraph>
            You are allowed to book any court that's available within the next 2 hours, however. This is a{' '}
            <i>last-minute</i> booking and doesn't count towards your 22-hour booking window.
          </Paragraph>
        </>,
      )
      return
    }
    setSelectedTimeslot(time)
    setSelectedBooking(null)
    setSelectedCourt(court)
    setModalVisible(true)
    hideAllNotifications()
  }
  const handleEdit = (booking) => {
    if (!validTimezone) {
      return
    }
    setSelectedBooking(booking)
    setSelectedTimeslot(null)
    setModalVisible(true)
    hideAllNotifications()
  }
  const handleDelete = (booking) => {
    if (!validTimezone) {
      return
    }
    if (booking && booking.id) {
      axios.delete(`/my/booking/${booking.id}`, {}).then(
        (result) => {
          notifySuccess('success', 'Booking cancelled', <>You've cancelled this court booking.</>, 4)
          setModalVisible(false)
          setDeleteConfirmationModalVisible(false)
          setSelectedBookingToDelete(null)
          refresh()
        },
        (e) => {
          notifyError('error', e)
        },
      )
    } else {
      notifyError('error', 'There was a problem deleting your court booking.')
    }
  }

  const showDeleteConfirmation = (booking) => {
    if (booking) {
      setSelectedBookingToDelete(booking)
      setDeleteConfirmationModalVisible(true)
      hideAllNotifications()
    }
  }
  if (!config.BOOKINGS_ENABLED) {
    return (
      <>
        <Title level={1}>Court Bookings </Title>
        {/* <Paragraph>
          Our courts are now closed and bookings have been disabled until the club opens again in the spring.
        </Paragraph> */}
        <Paragraph>
          Due to the bad weather the nets aren't up yet. Court bookings will open once the nets go up.
        </Paragraph>
      </>
    )
  }

  if (!validTimezone) {
    return (
      <>
        <Title level={1}>Court Bookings </Title>
        <InvalidTimezone />
      </>
    )
  }

  return (
    <>
      {!kioskMode && (
        <>
          <BookingDeleteConfirmationModal
            booking={selectedBookingToDelete}
            visible={deleteConfirmationModalVisible}
            onOk={handleDelete}
            onClose={() => setDeleteConfirmationModalVisible(false)}
          />
          <BookingMessageModal
            title={modalMessage?.title}
            message={modalMessage?.message}
            visible={messageModalVisible}
            onOk={closeMessage}
            onClose={closeMessage}
          />
          <BookingModal
            visible={modalVisible}
            onOk={handleSaveBooking}
            onCancel={handleCancel}
            court={selectedCourt}
            bookingTime={selectedTimeslot}
            bookingToEdit={selectedBooking}
          />
          <Title level={1}>Court Bookings </Title>
          <BookingInstructionsModal />
          {currentUser && (
            <Row justify="start" style={{ width: '100%', marginBottom: '2em' }}>
              <UpcomingBookings
                bookings={nextBookings}
                setDate={setDate}
                nextAllowed={nextAllowed}
                lastBooking={lastBooking}
              />
            </Row>
          )}
          <Divider />
          <Row justify="space-around" className="bookingHeading">
            <Col span={24} style={{ textAlign: 'center' }}>
              <Text className="bookingDateHeading">{displayDate}</Text>
            </Col>
            <Col span={bp.xs ? 24 : null}>{!kioskMode && getControls()}</Col>
          </Row>
        </>
      )}

      {kioskMode && (
        <div style={{ position: 'absolute', top: 25, right: 15, zIndex: 1 }}>
          <Text className="note">Last updated at {formatDateTime(refreshedAt, 'h:mm:ss a')}</Text>
        </div>
      )}

      {validTimezone && (
        <>
          <BookingsTable
            date={date}
            bookings={bookings}
            onAdd={handleAdd}
            onEdit={handleEdit}
            onDelete={showDeleteConfirmation}
          />
          {kioskMode ? (
            <Link to="/courtbooking" style={{ float: 'right', paddingTop: '2em' }}>
              <FullscreenOutlined title="Normal mode" />
            </Link>
          ) : (
            <Link to="/courtbookingkiosk" style={{ float: 'right', paddingTop: '2em' }}>
              <FullscreenOutlined title="Fullscreen mode" />
            </Link>
          )}
        </>
      )}
    </>
  )
}

export default BookingPage
