import { CURRENT_YEAR } from './util/commonUtil'

export const APRIL = 3
export const MAY = 4
export const JUNE = 5
export const JULY = 6
export const AUG = 7
export const SEPT = 8
export const OCT = 9
export const NOV = 10

export const MAY_1st = new Date(CURRENT_YEAR, MAY, 1)
export const JUNE_1st = new Date(CURRENT_YEAR, JUNE, 1)
export const JULY_1st = new Date(CURRENT_YEAR, JULY, 1)
export const AUG_1st = new Date(CURRENT_YEAR, AUG, 1)
export const SEPT_1st = new Date(CURRENT_YEAR, SEPT, 1)
export const OCT_1st = new Date(CURRENT_YEAR, OCT, 1)

export const REGISTRATION_OPENS = 'April 1'
export const CAMP_REGISTRATION_OPENS = 'April 1'

export const LADDERS_START = 'Mid-April'

export const SEASON_OPENING_MEET_AND_GREET = 'May 3'

export const JUNIOR_LESSONS_START = 'May 13'
export const JUNIOR_LESSONS_END = 'June 20'

export const JUNIOR_CAMPS_START = 'June 30'
export const JUNIOR_CAMPS_END = 'Aug 30'

export const ADULT_DOUBLES_ASSESSMENT = 'Apr 17 and 23'
export const ADULT_DOUBLES_START = 'May 22'
export const ADULT_DOUBLES_END = 'Sept 29'
export const ADULT_DOUBLES_PRICE = 20

export const ADULT_LESSONS_START = 'May 14'

export const SINGLES_DROPIN_START = 'May 7'
export const SINGLES_DROPIN_END = 'Oct 1'

export const SEASON_OPENING_FESTIVAL_DATE = 'June 7'
export const PARENT_CHILD_TOURN_DATE = 'June 21'
export const WIMBLEDON_DATE = 'July 12'
export const ICECREAM_TOUR_DATE = 'July 19'
export const JUNIOR_FRENCH_OPEN_DATE = 'May 18'

export const CLUB_CHAMPIONSHIPS_START = 'Aug 1'
export const CLUB_CHAMPIONSHIPS_FINALS_SINGLES_DATE = 'Sept 13-14'
export const CLUB_CHAMPIONSHIPS_FINALS_DOUBLES_DATE = 'Sept 20-21'

export const JUNIOR_CLUB_CHAMPIONSHIPS = 'Sept 6-7'

export const INTERURBAN_TRYOUTS = 'April 22 and 29'
export const INTERURBAN_TRYOUTS_TIME = '7-9pm'
