import { AUG_1st, JULY_1st, JUNE_1st, MAY, MAY_1st, OCT_1st, SEPT_1st } from '../constants'
import {
  add,
  differenceInCalendarDays,
  isAfter,
  isBefore,
  isMonday,
  isPast,
  isSameDay,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
  parseISO,
  set,
} from 'date-fns'
import { formatDateTime, isBetween, jsonDate, jsonDateShort } from './dateTimeUtil'

import { CURRENT_YEAR } from './commonUtil'
import _ from 'lodash'

const MAX_PER_SESSION = 4 * 3

// List of dates on which there won't be lessons.  Use format 'yyyy-mm-dd'
const NO_LESSONS_DATES = []

const WEEKS_TEMPLATE = []

const LESSON_NAMES = ['Fancy Footwork', 'Dynamic Doubles', 'Serve Mechanics', 'Drills and Games']
const LESSON_NAMES_BY_DATE = {}

export const getLessonDescription = (date) => {
  const key = _.isString(date) ? date : jsonDateShort(date)
  return LESSON_NAMES_BY_DATE[key]
}

// let index = 0
const START_DATE = set(new Date(), { month: MAY, date: 14 })
let date = new Date(START_DATE)
for (let i = 0; i < 18; i++) {
  const key = jsonDateShort(date)
  if (!_.includes(NO_LESSONS_DATES, key)) {
    const value = formatDateTime(date, 'eee MMMM d')
    WEEKS_TEMPLATE.push({ key, value })
    LESSON_NAMES_BY_DATE[key] = LESSON_NAMES[i % LESSON_NAMES.length]
  }
  date = add(date, { weeks: 1 })
}
const END_DATE = date

const TIMES_TEMPLATE = [
  { key: 'early', value: '7:15 - 8:15pm', note: '' },
  { key: 'late', value: '8:30 - 9:30pm', note: '' },
]

export const isLastMinuteEnabledForCurrentWeek = (now) => {
  const start = set(START_DATE, { date: 1 })
  return isBetween(now, start, END_DATE) && (isMonday(now) || isTuesday(now) || isWednesday(now))
}

export const isBeginnerEarlyWeek = (lesson) => {
  let { date } = lesson
  date = _.isString(date) ? parseISO(date) : date
  return isBetween(date, MAY_1st, JUNE_1st) || isBetween(date, JULY_1st, AUG_1st) || isBetween(date, SEPT_1st, OCT_1st)
}

export const isEarlyWeek = (level, date) => {
  date = _.isString(date) ? parseISO(date) : date
  const isBeg = level === 'beg'
  const isBegEarlyWeek = isBeginnerEarlyWeek({ date })
  return (isBeg && isBegEarlyWeek) || (!isBeg && !isBegEarlyWeek)
}

export const getSessions = (level, stats, now) => {
  const result = []

  for (const week of WEEKS_TEMPLATE) {
    const dateKey = week.key
    const date = parseISO(week.key)
    const isBeg = level === 'beg'
    const isEarly = isEarlyWeek(level, date)
    const lessonLabel = isBeg ? '' : `"${getLessonDescription(date)}" `

    for (const time of TIMES_TEMPLATE) {
      const daysUntilLesson = differenceInCalendarDays(date, now)
      const isLastMinute = daysUntilLesson >= 0 && daysUntilLesson < 3

      if ((isEarly && time.key === 'early') || (!isEarly && time.key === 'late')) {
        const timeslotStats = _.find(stats, (entry) => entry.dateKey === dateKey)
        const spotsRemaining = timeslotStats ? timeslotStats.spotsRemaining : MAX_PER_SESSION
        const spotsRemainingNote = spotsRemaining === 1 ? ' spot available' : ' spots available'
        const isFull = timeslotStats ? timeslotStats.isFull : false
        let note = `${lessonLabel}${isFull ? '(full)' : '(' + spotsRemaining + spotsRemainingNote + ')'}`
        note = (isLastMinute ? '(last-minute signup) ' : '') + note

        const formattedDate = formatDateTime(date, 'eee MMM d')
        // const value = `${isEarly ? '7:15pm' : '8:30pm'} - ${formattedDate}`
        const value = `${formattedDate} @ ${isEarly ? '7:15pm' : '8:30pm'}`
        result.push({ disabled: isFull, note, key: week.key, value })
      }
    }
  }

  return result
}
