import { Button } from 'antd'
import _ from 'lodash'

export const EmailLink = ({ email, children, ...props }) => {
  const emailAddr = `${_.toLower(email)}@hespelertennis.on.ca`
  return (
    <Button className="noPad" type="link" href={`mailto:${emailAddr}`} {...props}>
      {children || emailAddr}
    </Button>
  )
}
