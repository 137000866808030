import { Col, Row, Typography } from 'antd'

import { EmailLink } from '../common/EmailLink'
import { PersonWithPhoto } from './PersonWithPhoto'
import Section from '../common/Section'
import arunas from '../../assets/images/photos/arunasm.jpg'
import beem from '../../assets/images/photos/beem.png'
import chris from '../../assets/images/photos/chris.jpg'
import emily from '../../assets/images/photos/person-nophoto.png'
import erinh from '../../assets/images/photos/erin.jpg'
import freddi from '../../assets/images/photos/freddi.jpeg'
import jasonj from '../../assets/images/photos/jasonj.png'
import jocelynr from '../../assets/images/photos/person-nophoto.png'
import kevinw from '../../assets/images/photos/person-nophoto.png'
import lisaj from '../../assets/images/photos/lisaj.jpeg'
import mason from '../../assets/images/photos/mason.jpg'
import mehrunnisak from '../../assets/images/photos/mehrunnisak.jpg'
import michaeln from '../../assets/images/photos/person-nophoto.png'
import nathanh from '../../assets/images/photos/person-nophoto.png'
import nophoto from '../../assets/images/photos/person-nophoto.png'
import sarad from '../../assets/images/photos/person-nophoto.png'
import vidya from '../../assets/images/photos/vidya.jpg'

const { Paragraph, Title } = Typography

const DirectorsPage = () => {
  return (
    <>
      <Section title="Board of Directors and Coordinators">
        <Paragraph>
          The Hespeler Tennis Club is a non-profit organization run by a group of volunteer directors. A given director
          position is typically held for 1- or 2-year terms, and may be held for up to 2 consecutive terms.
        </Paragraph>
        <Title level={1}>Board of Directors</Title>
        <Row gutter={48} style={{ width: '100%', margin: 'auto', border: '0px solid black' }}>
          <PersonWithPhoto name="Mehrunnisa Kharodia" role="President" photo={mehrunnisak} />
          <PersonWithPhoto name="Chris Alexander" role="Vice-President" email="vicepresident" photo={chris} />
          <PersonWithPhoto name="Mason Doerr" role="Past President" email="pastpresident" photo={mason} />
          <PersonWithPhoto name="Arunas Macikunas" role="Treasurer" photo={arunas} />
          <PersonWithPhoto name="Freddi Goodman" role="Secretary" photo={freddi} />
          <PersonWithPhoto name="Vidya Sundar" role="Membership" photo={vidya} />
          <PersonWithPhoto name="Jocelyn Rooyakkers" role="Junior Membership" email="juniorclub" photo={jocelynr} />
          <PersonWithPhoto name="Jason Jenkins" role="Facilities / Maintenance" email="facilities" photo={jasonj} />
          <PersonWithPhoto
            name="Kevin Worley &amp; Michael Niestepski"
            role="At-Large"
            photo={kevinw}
            photo2={michaeln}
            email={false}
          />
        </Row>

        <Title level={1} style={{ marginTop: '4em' }}>
          Coordinators
        </Title>
        <Row gutter={48} style={{ width: '100%', margin: 'auto', border: '0px solid black' }}>
          <PersonWithPhoto
            name="Bee Macikunas &amp; Freddi Goodman (beg/int), Erin Hellinga (adv)"
            role="Adult Doubles"
            photo={beem}
            photo2={freddi}
            photo3={erinh}
            email="adultsocialdoubles"
          />
          <PersonWithPhoto name="Emily MacGillivary" role="Junior Programs" photo={emily} email="juniorclub" />
          <PersonWithPhoto name="Lisa Jenkins &amp; Bee Macikunas " role="Socials" photo={lisaj} photo2={beem} />
          <PersonWithPhoto name="Nathan Hellinga" role="Tournaments" photo={nathanh} />
          <PersonWithPhoto name="(vacant)" role="Fundraising" photo={nophoto} />
          <PersonWithPhoto name="(vacant)" role="Health &amp; Safety" email="healthandsafety" photo={sarad} />
          <PersonWithPhoto name="Erin Hellinga" role="Ladders" photo={erinh} />
          <PersonWithPhoto name="Mason Doerr" role="Tech / Website" photo={mason} email="webadmin" />
        </Row>
      </Section>

      <Section title="Director Role Descriptions">
        <Title level={3}>President</Title>
        <ul>
          <li>
            work with all members of the board to keep the club moving forward, ensuring that we stay true to the vision
            and mission statement of the club
          </li>
          <li>coordinate all aspects of running the club</li>
          <li>
            work in conjunction with all board members to set the annual calendar of events for the upcoming season, set
            registration rates and program fees, and set compensation structure and wages for all staff{' '}
          </li>
          <li>liaise with city staff </li>
          <li>liaise with other local tennis clubs</li>
        </ul>

        <Title level={3}>Vice-President</Title>
        <ul>
          <li>
            work in conjunction with President and board on big picture initiatives and planning and the execution of
            those initiatives and plans in preparation to take over the President role once the President has served
            their term{' '}
          </li>
          <li>
            this person holds another specific position on the board, but is earmarked for assuming the President's role
          </li>
        </ul>

        <Title level={3}>Treasurer</Title>
        <ul>
          <li>keep records of all income and expenses</li>
          <li>prepare year-end reports on financial position</li>
          <li>set budget guidelines for all cost centers for the coming year</li>
        </ul>

        <Title level={3}>Secretary</Title>
        <ul>
          <li>take and distribute minutes of all meetings</li>
          <li>keep Operations Guide up to date with all policies and changes to contacts</li>
        </ul>

        <Title level={3}>Junior Club Director</Title>
        <ul>
          <li>an adult whose mandate is to "speak" for the junior members at board meetings</li>
          <li>
            help to promote junior participation in programs (i.e. school presentations in early spring prior to
            registration, fun fairs, etc)
          </li>
        </ul>

        <Title level={3}>Membership Director</Title>
        <ul>
          <li>coordinate club promotion activities</li>
          <li>
            organize and oversee the registration process and maintenance of the membership database throughout the
            season
          </li>
        </ul>

        <Title level={3}>Facilities / Maintenance Director</Title>
        <ul>
          <li>coordinate all aspects of court and clubhouse upkeep and supplies</li>
          <li>
            liaise with city parks and rec staff for their assistance with fixing/maintaining the grounds, buildings,
            and courts
          </li>
        </ul>
      </Section>

      <Section title="League and Coordinator Role Descriptions">
        <Title level={3}>Adult Doubles Coordinator</Title>
        <ul>
          <li>
            coordinate with head pro to evaluate new and returning players' abilities to ensure people have registered
            for the appropriate session
          </li>
          <li>process registrations to schedule players into sessions by night preferences and ability</li>
          <li>set schedule of play for regular season</li>
          <li>ensure that all players know the rules of the league</li>
          <li>respond to inquiries, issues and suggestions relating to Adult Doubles Tennis</li>
        </ul>

        <Title level={3}>Singles Ladder Coordinator</Title>
        <ul>
          <li>create and maintain the ladder at the beginning of the season and update monthly</li>
          <li>create and maintain score sheets in the clubhouse and on website</li>
        </ul>

        <Title level={3}>Social Coordinator</Title>
        <ul>
          <li>plan and execute all aspects of all adult and junior social events</li>
          <li>ensure that the clubhouse is always stocked with items used primarily at socials and tournaments</li>
          <li>continually seek new and/or improved ways to offer social events for the HTC membership</li>
        </ul>

        <Title level={3}>Tech / Website Coordinator</Title>
        <ul>
          <li>update website as needed</li>
          <li>
            consult on upgrades, changes, and seasonal suspensions of service to internet and other technical hardware
            at the club, including computers, routers, phones, etc.
          </li>
        </ul>

        <Title level={3}>Fundraising Coordinator</Title>
        <ul>
          <li>
            continually seek and execute fundraising opportunities for future club requirements and endeavours as agreed
            by the board
          </li>
        </ul>
        <Title level={3}>Health &amp; Safety Coordinator</Title>
        <ul>
          <li>annually review first aid equipment / supplies available in clubhouse</li>
          <li>
            advise on modification of practices, or implementation of new measures, to improve the safe operation of the
            Club
          </li>
        </ul>
      </Section>
    </>
  )
}

export default DirectorsPage
