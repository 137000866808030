import { useContext, useEffect, useState } from 'react'

import AppContext from '../../components/common/AppContext'
import { GET } from '../../gql/booking.gql'
import _ from 'lodash'
import axios from '../../axios/axios'
import { config } from '../../util/config'
import { notifyError } from '../../util/notifyUtil'
import { useQuery } from '@apollo/client'

const useUserDirectory = (currentUser) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const result = currentUser ? await axios.get('/users-permissions/directory') : {}
        const sorted = _.sortBy(result?.data, 'lastName', 'firstName')
        setData(sorted)
      } catch (e) {
        setError(e)
        notifyError('error', e)
      } finally {
        setLoading(false)
      }
    })()
  }, [currentUser])

  return { users: data, loading, error }
}

export default useUserDirectory
