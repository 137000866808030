import { Table, Typography } from 'antd'

import useResponsive from '../../hooks/useResponsive'

const { Text } = Typography

const data = [
  {
    membershipType: 'Single',
    description: '(age 18 or over as of Dec 31)',
    returningMemberPrice: 170,
    newMemberPrice: 190,
  },
  {
    membershipType: 'Twosome',
    description: '(two adults from the same household)',
    returningMemberPrice: 255,
    newMemberPrice: 285,
  },
  {
    membershipType: 'Family',
    description: '(3+ members from the same household)',
    returningMemberPrice: 280,
    newMemberPrice: 315,
  },
  {
    membershipType: 'Senior',
    description: '(age 60 or over as of Dec.31)',
    returningMemberPrice: 115,
    newMemberPrice: 135,
  },
  {
    membershipType: 'Student',
    description: '(age 18 or over with student ID)',
    returningMemberPrice: 105,
    newMemberPrice: 125,
  },
  {
    membershipType: 'Junior',
    description: '(ages 5 to 17 as of Dec.31)',
    returningMemberPrice: 70,
    newMemberPrice: 90,
  },
]

const MembershipPrices = () => {
  const bp = useResponsive()

  const columns = [
    {
      title: '',
      render: (text, record) => (
        <>
          <b>{record.membershipType}</b>
          {bp.xs ? <br /> : ' '}
          <Text type="secondary" italic style={{ fontSize: '0.85em' }}>
            {record.description}
          </Text>
        </>
      ),
    },
    // {
    //   title: 'Returning members',
    //   align: 'right',
    //   dataIndex: 'returningMemberPrice',
    //   render: (text, record) => <span style={{ whiteSpace: 'nowrap' }}>${text}</span>,
    // },
    {
      title: 'Price',
      align: 'right',
      dataIndex: 'newMemberPrice',
      render: (text, record) => <span style={{ whiteSpace: 'nowrap' }}>${text}</span>,
    },
  ]
  return <Table className="membershipPricesTable" columns={columns} dataSource={data} size="small" pagination={false} />
}

export default MembershipPrices
