import { Alert, Button, Col, Row, Typography } from 'antd'
import { useContext, useState } from 'react'

import AppContext from '../common/AppContext'
import BookingDisplay from './BookingDisplay'
import NextBookingMessage from './NextBookingMessage'
import _ from 'lodash'

const { Title, Text, Paragraph } = Typography

const UpcomingBookings = ({ bookings, setDate, nextAllowed, lastBooking }) => {
  const [showAll, setShowAll] = useState(false)
  const { currentUser } = useContext(AppContext)

  const MAX = 5

  const bookingsWithOwner = _.map(bookings, (b) => {
    return { ...b, isOwnBooking: b.createdByUser?.id === currentUser?.id }
  })

  const bookingsToShow = showAll ? bookingsWithOwner : bookingsWithOwner?.slice(0, MAX)
  const hiddenCount = _.size(bookings) - MAX

  return (
    <>
      <Row justify="start" style={{ width: '100%', marginTop: '1em' }}>
        <Col xs={24} md={10} lg={8} xl={6} style={{ marginRight: '0em' }}>
          <>
            <Title level={2} className="noPad">
              My court bookings
            </Title>
            <div style={{ marginTop: '1em' }}>
              {!_.isEmpty(bookingsToShow) ? (
                <>
                  {bookingsToShow.map((booking) => {
                    return (
                      <>
                        <BookingDisplay title="Next booking" booking={booking} onClick={setDate} />
                        <br />
                      </>
                    )
                  })}
                  {hiddenCount > 0 && !showAll && (
                    <span className="bookingUpcoming">
                      + {hiddenCount} more
                      <Button type="link" size="small" style={{ fontSize: '0.9em' }} onClick={() => setShowAll(true)}>
                        Show all
                      </Button>
                    </span>
                  )}
                </>
              ) : (
                <Paragraph className="note">You have no upcoming court bookings.</Paragraph>
              )}
            </div>
          </>
        </Col>
        <Col xs={24} md={14} lg={16} xl={18} style={{ marginTop: '1em' }}>
          <div style={{ marginTop: '0.5em', verticalAlign: 'middle' }}>
            <NextBookingMessage nextAllowed={nextAllowed} lastBooking={lastBooking} />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default UpcomingBookings
