import { Form, Switch, Typography } from 'antd'

import AppContext from './AppContext'
import { useContext } from 'react'

const { Paragraph } = Typography

const PublicProfileInput = ({ editing = false, fullwidth = false }) => {
  const { currentUser } = useContext(AppContext)
  return (
    <Form.Item label="Include my name in the HTC user directory?">
      <Paragraph className="note" style={fullwidth ? {} : { maxWidth: '35em' }}>
        If this is set to 'Yes', your name will appear in the list of players that can be selected when booking a court.
        Only your name will be visible (no other contact info), and it will only be seen by logged-in HTC members.
      </Paragraph>
      {editing || true ? (
        <Form.Item name="isProfilePublic" valuePropName="checked" noStyle>
          <Switch disabled={!editing} unCheckedChildren="No" checkedChildren="Yes" size="default" />
        </Form.Item>
      ) : currentUser.isProfilePublic ? (
        'Yes'
      ) : (
        'No'
      )}
    </Form.Item>
  )
}

export default PublicProfileInput
