import { ADULT_DOUBLES_END, ADULT_DOUBLES_START, INTERURBAN_TRYOUTS, INTERURBAN_TRYOUTS_TIME } from '../../constants'
import { Alert, Button, Row, Typography } from 'antd'

import { EmailLink } from '../common/EmailLink'
import InterurbanClubInfo from './InterurbanClubInfo'
import InterurbanSchedule from './InterurbanSchedule'
import { Link } from 'react-router-dom'
import Section from '../common/Section'
import rulesDoc from '../../assets/docs/interurban-rules-2017.pdf'

const { Paragraph, Title, Text } = Typography

const Interurban = () => {
  return (
    <>
      <Section title="Interurban Team Tennis">
        <Paragraph>
          We're happy to announce that the Hespeler Tennis Club will again participate in the Interurban tennis league
          in 2025!
        </Paragraph>
        <Paragraph>
          <Section level={2} title="Tryouts" />
          Please let our Interurban co-ordinator Kelly know if you are interested in trying out for the team by sending
          her an email to <a href="mailto:kellyjubinville@yahoo.com">kellyjubinville@yahoo.com</a> or by text at
          519-766-2240.
          <br />
          <br />
          Tryouts for the team will be required and will be held{' '}
          <b>
            {INTERURBAN_TRYOUTS}, {INTERURBAN_TRYOUTS_TIME}
          </b>
        </Paragraph>
        <Section level={2} title="About Interurban" />
        <Paragraph>
          Interurban is a competitive doubles league which will play Tuesday evenings, and will both play at home and
          travel to other clubs in the area.
        </Paragraph>
        <Paragraph></Paragraph>
        <Paragraph>
          The team will be selected by our head pro, Dan Darney and Interurban Coordinator, Kelly Jubinville.
        </Paragraph>
        <Paragraph>
          Team members are selected based on skill level, sportsmanship and availability. Team members should be
          generally available on Tuesday evenings from May to July, 2025, including playoff dates. Please let Kelly know
          of any already planned vacations which will prevent you from playing during these dates. Four men and four
          women are required every given Tuesday evening.
        </Paragraph>
        <Paragraph>
          Please also be aware that travel would be required on an alternating weekly Tuesday evening basis. The travel
          would be to Waterloo, Cambridge and Guelph for ‘away’ games where punctuality for 6:45 pm warm up (games start
          at 7 pm) at the destination club is extremely important. For home games, players will be rotating-contacted to
          start at 6:30.
        </Paragraph>
        <Paragraph>
          Your level of tennis and sportsmanship will be a large factor in selecting the team and especially to those
          who can train with the team. We will look at current ability and past tournament results, if available.
        </Paragraph>
        <Paragraph>
          We look forward to the Hespeler Tennis Club competing again in this league and we will have some great home
          games to which all club members will be invited to support our team.
        </Paragraph>
        <Paragraph></Paragraph>
        {/* <Paragraph>
          The team will be comprised of <b>6 men and 6 women</b>, and each week we play we’ll have 4 of those men and 4
          of those women competing. Match days consist of two men’s doubles matches, two ladies’ doubles matches and
          four mixed doubles matches (all with the same players). Match days will be <b>Tuesdays</b> from May 16th to
          July 18 with July 25 being a rain date and August 1 and 8 being playoff days (if we are successful).
        </Paragraph>
        <Paragraph>
          Members who are selected for the team will be required to pay a registration fee of $30 to HTC to assist in
          offsetting the costs associated with participating.
        </Paragraph> */}
        {/* <Paragraph>
          Anyone interested in participating should email HTC’s Interurban email account{' '}
          <EmailLink email="interurban" /> or indicate their interest during club registration.
        </Paragraph> */}
      </Section>
      <Section title="Rules" level={2}>
        <Paragraph>
          All team members should be familiar with and abide by the{' '}
          <a target="_blank" rel="noreferrer" href={rulesDoc}>
            Interurban rules
          </a>
        </Paragraph>
        {/* <Text italic>
          (Note that these are the 2017 rules and will likely be updated slightly before the 2025 season starts)
        </Text> */}
        <Paragraph>
          Interurban team members must also abide by our <Link to="/code-of-conduct">Code of conduct</Link> during
          competition.
        </Paragraph>
      </Section>
      <Section title="Participating clubs" level={2}>
        <Row justify="start">
          Check back for more info about the participating clubs for the 2025 season.
          {/* <InterurbanClubInfo
            name="Hespeler Tennis Club"
            url="https://hespelertennis.on.ca/"
            address="23 Forbes St Cambridge, ON N3C2E1"
          />
          <InterurbanClubInfo
            name="Greystone Racquet Club"
            url="https://www.greystoneclub.ca"
            address="155 Northfield Dr W, Waterloo ON N2L5J3"
            // dates="May 16"
          />
          <InterurbanClubInfo
            name="Cambridge Tennis Club"
            url="https://www.cambridgetennisclub.ca/"
            address="305 Dundas St, Cambridge ON N1R5S2"
            // dates="May 30"
          />
          <InterurbanClubInfo
            name="Royal City Tennis Club"
            url="http://www.rctc.ca"
            address="70 Municipal St, Guelph ON N1G4P9"
            // dates="June 27"
          />
          <InterurbanClubInfo
            name="Cutten Club Tennis Club"
            url="https://members.cuttenfields.com/Tennis"
            address="190 College Ave E, Guelph ON N1H6L3"
            // dates="July 11"
          />
          <InterurbanClubInfo
            name="Waterloo Tennis Club"
            url="https://www.waterlootennis.com"
            address="54 Central St, Waterloo ON N2L3A8"
            // dates="July 18"
          /> */}
        </Row>
      </Section>
      <Section level={2} title="Schedule and results">
        <Paragraph>The schedule and results will be posted here when available.</Paragraph>
        {/* <InterurbanSchedule /> */}

        {/* <Paragraph>
          There are 5 away and 5 home regularly-scheduled Tuesday night matches from May 16 to July 18 this season, with
          a rain date on July 25 if needed.
        </Paragraph>
        <Paragraph>
          <b>Home matches:</b> May 23, June 6, June 13, June 20, and July 4.
        </Paragraph>
        <Paragraph>
          <b>Away matches:</b> May 16, May 30, June 27, July 11, and July 18.
        </Paragraph>
        <Paragraph>
          The playoffs will be on August 1 and August 8, participation and locations depend on the results of the
          regular season.
        </Paragraph> */}
      </Section>
    </>
  )
}

export default Interurban
