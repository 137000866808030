import { Col, Divider, Row, Typography } from 'antd'

import { EmailLink } from '../common/EmailLink'
import _ from 'lodash'
import nophoto from '../../assets/images/photos/person-nophoto.png'

const photoStyles = { border: '2px solid #bbb', height: '150px', width: '150px', borderRadius: '20%' }
const multiPhotoStyles = { ...photoStyles, marginLeft: '1em' }

const { Text, Title } = Typography
export const PersonWithPhoto = ({ name, role, email, photo = nophoto, photo2, photo3 }) => {
  return (
    <>
      <Col style={{ margin: 'auto', textAlign: 'center' }}>
        <Title level={3}>{role}</Title>
        <img src={photo} alt="name" style={photoStyles} />
        {photo2 && <img src={photo2} alt="name" style={multiPhotoStyles} />}
        {photo3 && <img src={photo3} alt="name" style={multiPhotoStyles} />}
        <br />
        {email !== false ? <EmailLink email={email ?? role}>{name}</EmailLink> : name}
      </Col>
    </>
  )
}
