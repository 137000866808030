import { Col, Row, Typography } from 'antd'

import { EmailLink } from '../common/EmailLink'
import Note from '../common/Note'
import Section from '../common/Section'
import headProPhoto from '../../assets/images/photos/headpro.jpg'

const { Paragraph, Title } = Typography

const CoachingPage = () => {
  return (
    <>
      <Section title="Coaching Staff">
        <Title level={2}>Head Pro - Dan Darney</Title>
        <Row justify="space-between" gutter={20}>
          <Col xs={24}>
            <img className="coachPhoto" src={headProPhoto} alt="Head Pro - Dan Darney" />
            <Paragraph>2025 will be Dan’s fourth year as the head pro at Hespeler Tennis Club.</Paragraph>
            <Paragraph>
              Dan started playing tennis as an 11-year-old and was immediately hooked! He trained as a junior and
              competed all over Southern England. At age 17 Dan won a scholarship to attend the Stonebridge Tennis
              Academy, which was run by Adrian Stonebridge, a well-known tennis instructor in the UK who did a
              television series and authored a book called "Tennis that Counts".
            </Paragraph>

            <Paragraph>
              Along with a tennis teaching certificate from the Stonebridge Tennis Academy Dan also certified as a coach
              with the British LTA (Lawn Tennis Association) and with the 'Professional Tennis Registry'.
            </Paragraph>

            <Paragraph>
              Dan taught full-time at several clubs and on a tennis resort in the UK. In the mid-90s Dan moved to Canada
              and taught at the North Vancouver Rec Centre and at People's Courts in Coquitlam BC.
            </Paragraph>

            <Paragraph>
              Dan joined the Hespeler Tennis Club as a member in 2007. He has enjoyed being here over the past 16 years
              and spent 5 of those years serving on the board of directors.
            </Paragraph>

            <Paragraph>
              During the winter of 2022/23 Dan got certified with Tennis Canada and completed his CPR training.
            </Paragraph>

            <Paragraph>
              He is looking forward to a fun-filled season this summer and is available for lessons during weekends and
              evenings. Dan can be reached at 416-567-2638 or <EmailLink email="headpro" />
            </Paragraph>
          </Col>
        </Row>
        <Title level={2}>Senior Coach</Title>
        <Paragraph>More information coming soon.</Paragraph>
      </Section>
    </>
  )
}

export default CoachingPage
