import { Button, Form, Input, Modal, Space, Typography } from 'antd'
import { ERRORS, transformServerError } from '../../util/errorUtil'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'

import AppContext from '../common/AppContext'
import { EmailLink } from '../common/EmailLink'
import { LOGIN } from '../../gql/auth.gql'
import { Link } from 'react-router-dom'
import axios from '../../axios/axios'
import { notifySuccess } from '../../util/notifyUtil'

const { Paragraph, Text } = Typography

const ForgotPassword = ({ visible, onSuccess, onClose }) => {
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const { currentUser } = useContext(AppContext)

  const title = currentUser ? 'Reset Password' : 'Forgot Password'

  useEffect(() => {
    if (visible) {
      setError(false)
    }
  }, [visible])

  const doForgotPassword = async (fields) => {
    let username = currentUser?.username ?? fields.username
    try {
      setLoading(true)
      const result = await axios.post('/auth/forgot-password', { username }, { noauth: true })
      onSuccess(result.data)
      notifySuccess(
        'forgot-password',
        'Password reset email sent',
        "Please check your email for a password-reset link.  Be sure to check your spam folder if it doesn't arrive soon.",
        10,
      )
    } catch (e) {
      console.error('Forgot password error', e)
      const { code, description } = transformServerError(e)
      if (!code || code === ERRORS.UNKNOWN) {
        setError('Sorry, an unknown error occurred while trying to send the reset-password email.')
      } else {
        setError(description)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal title={title} visible={visible} onCancel={onClose} footer={null} destroyOnClose>
      <Form onFinish={doForgotPassword} layout="vertical">
        <Space direction="vertical">
          {!currentUser ? (
            <>
              <Paragraph>Enter your username and we'll send you an email with a password-reset link.</Paragraph>
              <Form.Item name="username" required={false} rules={[{ required: true, message: '' }]}>
                <Input autoFocus placeholder="Username" />
              </Form.Item>
            </>
          ) : (
            <>
              <Paragraph>Click the button below and we'll send you an email with a password-reset link.</Paragraph>
            </>
          )}
          {error && (
            <Paragraph type="danger">
              Request failed.{' '}
              <Text danger={false}>
                {error} Please{' '}
                <EmailLink email="webadmin" style={{ textDecoration: 'underline black' }}>
                  contact us
                </EmailLink>{' '}
                for assistance.
              </Text>
            </Paragraph>
          )}
          <Space direction="horizontal">
            <Form.Item>
              <Button htmlType="submit" type="primary" loading={loading}>
                Send reset-password link
              </Button>
            </Form.Item>
          </Space>
          {!currentUser ? (
            <Paragraph className="note">
              If you can't remember your username, or you didn't get a password-reset email (and you've confirmed it's
              not in your spam folder), please <EmailLink email="webadmin">contact us</EmailLink> for assistance.
            </Paragraph>
          ) : (
            <Paragraph className="note">
              If you don't get a password-reset email (and you've confirmed it's not in your spam folder), please{' '}
              <EmailLink email="webadmin">contact us</EmailLink> for assistance.
            </Paragraph>
          )}
        </Space>
      </Form>
    </Modal>
  )
}

export default ForgotPassword
