import { Alert, Typography } from 'antd'
import { durationInWords, formatDateTime } from '../../util/dateTimeUtil'

import { getCourtsText } from '../../util/bookingUtil'
import { intervalToDuration } from 'date-fns'

const { Text, Title, Paragraph } = Typography

const NextBookingMessage = ({ nextAllowed, lastBooking }) => {
  if (!nextAllowed || !lastBooking) {
    return null
  }

  const lastBookingTime = new Date(lastBooking?.createdAt)

  const lastBookingStr = `${getCourtsText(lastBooking)} on ${formatDateTime(
    lastBooking.startTime,
    "E MMM d' at 'h:mm a",
  )}`

  const content = (
    <>
      <Paragraph className="note" style={{ marginBottom: '0.5em' }}>
        <b>
          You'll be able to book another court in {durationInWords(nextAllowed, false)} (at{' '}
          {formatDateTime(nextAllowed, 'h:mm a')})
        </b>
        . You must wait at least 22 hours between bookings, and you last booked at{' '}
        {formatDateTime(lastBookingTime, "h:mm a ' on 'MMM d")} (booking was for {lastBookingStr}).
      </Paragraph>
    </>
  )

  return <Alert type="bookingTimer" message={content} />
}

export default NextBookingMessage
