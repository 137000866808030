import './App.css'

import { Button, Col, Layout, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { AppProvider } from './components/common/AppContext'
import AppRoutes from './components/common/Routes'
import { CURRENT_YEAR } from './util/commonUtil'
import ForgotPassword from './components/login/ForgotPassword'
import { Link } from 'react-router-dom'
import Login from './components/login/Login'
import Menu from './components/common/Menu'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import ProfileMenu from './components/common/ProfileMenu'
import _ from 'lodash'
import axios from './axios/axios'
import { config } from './util/config'
import useQueryParams from './hooks/useQueryParams'

const { Header, Content, Footer } = Layout

const App = () => {
  const { pathname } = useLocation()
  const pageClassName = _.kebabCase(pathname)

  const [loginVisible, setLoginVisible] = useState()
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState()
  const [loginInProgress, setLoginInProgress] = useState(true)
  const [currentUser, setCurrentUser] = useState()
  const [menuEnabled, setMenuEnabled] = useState(true)
  const queryParams = useQueryParams()

  const login = (loginResult) => {
    setCurrentUser(loginResult.user)
    localStorage.setItem('authToken', loginResult.jwt)
    setLoginVisible(false)
  }

  const logout = () => {
    setCurrentUser(null)
    localStorage.removeItem('authToken')
  }

  const timezoneOffset = new Date().getTimezoneOffset() / 60
  const validTimezone = timezoneOffset === 4 || timezoneOffset === 5

  const handleForgotPassword = () => {
    setLoginVisible(false)
    setForgotPasswordVisible(true)
  }

  const appContext = {
    currentUser,
    setCurrentUser,
    loginInProgress,
    login,
    logout,
    menuEnabled,
    setLoginVisible,
    handleForgotPassword,
    validTimezone,
  }
  // useEffect(() => {
  //   let menuEnabledParam = queryParams.get('m')
  //   if (_.isNil(menuEnabledParam)) {
  //     menuEnabledParam = localStorage.getItem('menuEnabled')
  //   } else {
  //     localStorage.setItem('menuEnabled', menuEnabledParam || '0')
  //   }
  //   setMenuEnabled(menuEnabledParam === '1')
  // }, [queryParams])

  useEffect(() => {
    ;(async () => {
      if (!currentUser && loginInProgress) {
        try {
          setLoginInProgress(true)
          const result = await axios.get('/users/me')
          const curUser = result?.data
          setCurrentUser(curUser)
        } catch (e) {
          console.error(e)
        } finally {
          setLoginInProgress(false)
        }
      }
    })()
  }, [currentUser, loginInProgress])

  const initialOptions = {
    currency: 'CAD',
    intent: 'capture',
    'client-id': config.PAYPAL_CLIENTID,
  }

  return (
    <>
      <PayPalScriptProvider options={initialOptions}>
        <AppProvider value={appContext}>
          <div className="background" />
          <div className={`page ${pageClassName}`}>
            {menuEnabled && (
              <Row className="menuRow" justify="space-between" wrap={false}>
                <Menu />
                <ProfileMenu onShowLogin={() => setLoginVisible(true)} />
              </Row>
            )}
            <Layout className="layout">
              <Header className="header">
                <Row wrap={true} justify="space-between">
                  <Col>
                    <Link to="/">
                      <img className="logo" alt="" />
                    </Link>
                  </Col>
                  {/* <Col xs={0} sm={6} style={{ textAlign: 'right', paddingRight: '10px' }}>
                    <Link to="/reserve">
                      <Button type="primary">Book a court</Button>
                    </Link>
                  </Col> */}
                </Row>
              </Header>
              <Content className="content">
                <AppRoutes />
              </Content>
              <Footer className="footer">Copyright ©{CURRENT_YEAR} Hespeler Tennis Club</Footer>
            </Layout>
          </div>
          <Login
            visible={loginVisible}
            onSuccess={login}
            onClose={() => setLoginVisible(false)}
            onForgotPassword={handleForgotPassword}
          />
          <ForgotPassword
            visible={forgotPasswordVisible}
            onSuccess={() => setForgotPasswordVisible(false)}
            onClose={() => setForgotPasswordVisible(false)}
          />
        </AppProvider>
      </PayPalScriptProvider>
    </>
  )
}

export default App
