import { ADULT_DOUBLES_END, ADULT_DOUBLES_START } from '../../constants'
import { Alert, Button, Space, Typography } from 'antd'

import { EmailLink } from '../common/EmailLink'
import { Link } from 'react-router-dom'
import Section from '../common/Section'
import ratingsGuidePdf from '../../assets/docs/TennisCanadaSelfRatingGuide.pdf'

const { Paragraph, Title, Text } = Typography

const AdultDoubles = () => {
  return (
    <>
      <Section title="Adult Social Doubles">
        {/* <Alert
          description={
            <>
              <b>NOTE:</b> The details below are subject to change as we continue to plan for the upcoming season.
            </>
          }
        /> */}
        {/* <Alert
          description={
            <>
              <b>Signups have now closed for this program.</b> If you haven't signed up but are interested in
              participating, please email <EmailLink email="adultsocialdoubles" /> to be added to the spares list or put
              on a waiting list.
            </>
          }
        /> */}
        <Paragraph>
          Adult Social Doubles is for players interested in playing mixed doubles tennis in a fun but competitive
          format.
        </Paragraph>
        {/* <Paragraph>
          All participants must be able to serve and sustain a rally and keep score (level 2.0+ as per the{' '}
          <a href={ratingsGuidePdf} target="_blank" rel="noreferrer">
            Tennis Canada rating guide
          </a>
          ).
        </Paragraph> */}
        <Paragraph>
          Players will register as <b>individuals</b> (not with a partner) and will be paired up with different doubles
          partners for each set.
        </Paragraph>
        <Paragraph>Registration fees collected help cover the cost of new balls each week.</Paragraph>
        <Title level={1}>Register</Title>
        <Paragraph>
          {/* <Link to="/adult-doubles-register">
            <Button type="primary">Sign up now</Button>{' '}
          </Link> */}
          {/* <b>Registration is now closed</b> */}
        </Paragraph>
        <Paragraph>
          <b>Registration:</b> Opens <b>Saturday April 26</b>. Please signup by Sunday May 4 so the organizers can begin
          preparing the schedule.
        </Paragraph>
        <Paragraph>
          <b>Cost:</b> $20 for the entire season
        </Paragraph>
        <Paragraph>
          <b>Assessments:</b> Quick drop-in assessments will be carried out by our head coach Dan Darney to ensure
          people are scheduled in the correct session based on their skill level. If you have not already been assessed
          by Dan or if you area returning player and wish to move to a higher playing level, please drop by the club
          during one of these times.
        </Paragraph>
        <ul>
          <li>
            <b>Thursday April 17</b> from 6:30-8:30pm
          </li>
          <li>
            <b>Wednesday April 23</b> from 6:30-8:30pm
          </li>
          <li>
            Rain date: <b>Thursday April 24</b> from 6:30-8:30pm
          </li>
        </ul>
        <Title level={1}>General information and format</Title>
        <Paragraph>
          The program runs <b>Monday</b> and <b>Thursday</b> nights, <b>6:30-9:30pm,</b> from{' '}
          <b>
            {ADULT_DOUBLES_START} - {ADULT_DOUBLES_END}
          </b>{' '}
          (19 weeks).
        </Paragraph>
        <Paragraph>
          There will be <b>two 90-minute sessions per night</b> on <b>Mondays</b> and <b>Thursdays</b>. You will only
          play in one session per week. There will be <b>three sets per session</b>, each set lasting{' '}
          <b>30 mins each</b>, with different partners and opponents for each set.
        </Paragraph>
        <Paragraph>
          Due to the high demand for this program, we are currently limiting registrations to <b>one session</b> to
          allow more people the opportunity to participate. There may be up to 8 nights throughout the summer when you
          are <b>not</b> scheduled to play. During signup, you will be able to let us know if you would like to register
          for a second session, if there is future availability.
        </Paragraph>
        <Paragraph>
          The schedule for May and June will be emailed out at the start of the season, to let you know what specific
          nights and times you are playing. A survey will be sent out in June to gather everyone’s July-Sept
          availability and then the schedule for the remainder of the season will be prepared and communicated.
        </Paragraph>
        <Paragraph>
          A list of potential spares will be made available in case you cannot make your assigned time slot but the onus
          is always on each player to arrange their own spare.
        </Paragraph>
        <Title level={1}>Session details</Title>
        {/* <Paragraph>The skill level of each session will be determined and communicated shortly.</Paragraph> */}
        <Paragraph>The cost for the program is $20 for the season.</Paragraph>
        {/* <Paragraph>
          <Title level={2}>Sunday evenings</Title>
          <ul>
            <li>
              <b>Multi-level intermediate</b> session (level 3.0 - 3.5) <b>6:30 - 8:00pm</b>
            </li>
          </ul>
        </Paragraph> */}
        <Paragraph>
          <Title level={2}>Monday evenings</Title>
          <ul>
            <li>
              <b>TBD</b> session <b>6:30 - 8:00pm</b>
            </li>
            <li>
              <b>Intermediate</b> session (level 3.0) <b>8:00 - 9:30pm</b>
            </li>
            <li>Note there are no games on the 4 statutory holidays</li>
          </ul>
        </Paragraph>
        <Paragraph>
          <Title level={2}>Thursday evenings</Title>
          <ul>
            <li>
              <b>Advanced</b> session (level 4.0+) <b>6:30 - 8:00pm</b>
            </li>
            <li>
              <b>High intermediate</b> session (level 3.5) <b>8:00 - 9:30pm</b>
            </li>
          </ul>
        </Paragraph>
        <Title level={1}>Rules and Procedures</Title>
        <Paragraph>
          <ul>
            <li>Please arrive for your scheduled time slot at least 5 minutes early.</li>
            <li>Pairs should be formed randomly.</li>
            <li>There will be a 5 minute warm-up for the first set. No warm-up for latecomers.</li>
            <li>
              Everyone gets "first-in" on their first serve of the night (but be reasonable about the number of serves
              you take).
            </li>
            <li>
              It is sudden-death on the SECOND deuce. This means the first deuce in a game is played out as normal (the
              next point won results in an advantage for one team). If the next point after that brings the score back
              to deuce, the subsequent point will be sudden-death to win the game. On the sudden-death point, the
              receiving team determines which player will receive the serve.
            </li>
            <li>
              Sets keep going until the 30-minute time is up (no tie-breaks, no limit on max score in a set). Final
              score could be, for example, 5-3 or 8-1.
            </li>
            <li>A "last game" warning will be called with 2 or 3 minutes remaining.</li>
            <li>
              If the set ends in a tie after "last game" is called, a mini-tiebreak will be played to determine the
              winner. The mini-tiebreak will be the best of 3 points (i.e. 2 points wins the tiebreaker). Servers get
              only one serve each in the tiebreak.
            </li>
            <li>
              After the 30 minute set is up, the winning pair from each set moves one court up, towards court 1
              (farthest from the parking lot) and splits so they now become opponents for the next set. If the winning
              pair was already on court 1, they just stay there and split. The losing pair from each set moves one court
              down, towards court 3 (closest to the parking lot) and splits so they now become opponents. If the losing
              pair was already on court 3, they just stay there and split.{' '}
            </li>
            <li>Scores are not recorded.</li>
            <li>
              Players are responsible for finding their own spare from the spares list ahead of time if unable to make
              their assigned night.{' '}
              <b>
                The success of Adult Social Doubles depends on each and every scheduled player being there or having
                someone else arranged to cover for them!
              </b>{' '}
              Please send an email to <EmailLink email="adultsocialdoubles" /> or text Bee at 519-729-6502 to
              communicate when you have arranged a spare.
            </li>
            <li>
              Players who fail to arrange a spare more than twice in the season will be contacted for an explanation and
              may be removed from the schedule for the remainder of the season.{' '}
            </li>
            <li>
              In case of poor weather, watch for an email notification no later than 15 minutes before the start of play
              to communicate if play has been canceled. Rainout cancellations will not be made up.
            </li>
            <li>No games on statutory holidays.</li>
            <li>
              Any member may at any time contact the Adult Doubles coordinators regarding any comments/concerns about
              the program. Email: <EmailLink email="adultsocialdoubles" /> The coordinators will ensure prompt and
              discrete correspondence (Freddi Goodman, Bee Lee, and Erin Hellinga)
            </li>
          </ul>
        </Paragraph>
      </Section>
    </>
  )
}

export default AdultDoubles
