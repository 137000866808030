import {
  ADULT_DOUBLES_ASSESSMENT,
  ADULT_DOUBLES_START,
  ADULT_LESSONS_START,
  CAMP_REGISTRATION_OPENS,
  CLUB_CHAMPIONSHIPS_FINALS_DATE,
  CLUB_CHAMPIONSHIPS_FINALS_DOUBLES_DATE,
  CLUB_CHAMPIONSHIPS_FINALS_SINGLES_DATE,
  CLUB_CHAMPIONSHIPS_START,
  ICECREAM_TOUR_DATE,
  INTERURBAN_TRYOUTS,
  JUNIOR_CAMPS_START,
  JUNIOR_CLUB_CHAMPIONSHIPS,
  JUNIOR_FRENCH_OPEN_DATE,
  JUNIOR_LESSONS_START,
  LADDERS_START,
  PARENT_CHILD_TOURN_DATE,
  REGISTRATION_OPENS,
  SEASON_OPENING_FESTIVAL_DATE,
  SINGLES_DROPIN_START,
  WIMBLEDON_DATE,
} from '../../constants'

import CalendarMonth from './CalendarMonth'
import GoogleCalendar from './GoogleCalendar'
import { Row } from 'antd'
import Section from '../common/Section'

const entries = {
  april: [
    { date: REGISTRATION_OPENS, label: 'Club registration opens' },
    { date: CAMP_REGISTRATION_OPENS, label: 'Summer camp registration opens' },
    { date: LADDERS_START, label: <>Ladders begin</> },
    { date: ADULT_DOUBLES_ASSESSMENT, label: <>Adult doubles assessments</>, note: '' },
    { date: 'April 26', label: <>Adult doubles registration</>, note: '' },
    { date: INTERURBAN_TRYOUTS, label: <>Interurban tryouts</>, note: '' },
  ],
  may: [
    { date: 'May 3', label: <>Season Opening Meet &amp; Greet</> },
    { date: 'May 10 and 24', label: <>Free days in May</> },
    // { date: SINGLES_DROPIN_START, label: <>Int/Adv Drop-ins begin</>, note: '' },
    { date: ADULT_DOUBLES_START, label: <>Adult Social Doubles begins</> },
    { date: JUNIOR_LESSONS_START, label: <>Junior lessons start</> },
    { date: ADULT_LESSONS_START, label: <>Adult lessons start</> },
  ],
  june: [
    { date: SEASON_OPENING_FESTIVAL_DATE, label: <>Season Opening Tennis Festival</> },
    { date: PARENT_CHILD_TOURN_DATE, label: <>Parent/child tournament</> },
    { date: JUNIOR_CAMPS_START, label: <>Junior camps start</> },
  ],
  july: [
    { date: 'July 1 - Aug 7', label: <>Swing &amp; Splash camp (Tues &amp; Thurs)</> },
    { date: WIMBLEDON_DATE, label: <>Wimbledon Social</> },
    { date: ICECREAM_TOUR_DATE, label: <>Ice Cream Tournament</> },
    { date: 'July 26', label: <>Multicultural Festival</> },
  ],
  aug: [{ date: CLUB_CHAMPIONSHIPS_START, label: <>Adult Club Championships begin</> }],
  sept: [
    { date: JUNIOR_CLUB_CHAMPIONSHIPS, label: <>Junior Club Championships</> },
    { date: CLUB_CHAMPIONSHIPS_FINALS_SINGLES_DATE, label: <>Adult Club Championship Singles finals</> },
    { date: CLUB_CHAMPIONSHIPS_FINALS_DOUBLES_DATE, label: <>Adult Club Championship Doubles finals</> },
  ],
}

const CalendarPage = () => {
  return (
    <>
      <Section title="Calendar">
        <Row gutter={10}>
          <CalendarMonth monthName="April" entries={entries.april} />
          <CalendarMonth monthName="May" entries={entries.may} />
          <CalendarMonth monthName="June" entries={entries.june} />
          <CalendarMonth monthName="July" entries={entries.july} />
          <CalendarMonth monthName="August" entries={entries.aug} />
          <CalendarMonth monthName="September" entries={entries.sept} />
        </Row>
      </Section>

      <GoogleCalendar />
    </>
  )
}

export default CalendarPage
